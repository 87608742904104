import axios from 'axios'

export default {
  state: {
    isFetching: false,
    error: null,
    dealer: {},
    thankYou: false,
    isProcessing: false
  },
  getters: {
    isB2cFetching: state => state.isFetching,
    isB2cProcessing: state => state.isProcessing,
    b2cError: state => (state.error && state.error.status !== 400 ? state.error : null),
    b2cDealer: state => state.dealer,
    b2cThankYou: state => state.thankYou
  },
  mutations: {
    B2C_IS_FETCHING(state) {
      state.isFetching = true
      state.error = null
    },
    B2C_IS_PROCESSING(state, value = true) {
      state.isProcessing = value
    },
    B2C_IS_NOT_FETCHING(state) {
      state.isFetching = false
      state.error = null
    },
    B2C_ERROR(state, payload) {
      state.isFetching = false
      state.error = payload
    },
    B2C_GET_DEALER_SUCCESS(state, payload) {
      state.isFetching = false
      state.error = null
      state.dealer = payload
    },
    B2C_THANK_YOU(state, value = true) {
      state.isFetching = false
      state.isProcessing = false
      state.error = null
      state.thankYou = value
    }
  },
  actions: {
    B2C_GET_DEALER({ commit }, token) {
      return new Promise(resolve => {
        commit('B2C_IS_FETCHING')
        axios
          .get(process.env.VUE_APP_API_BASE_URL + '/public/dealer/' + token)
          .then(res => {
            commit('B2C_GET_DEALER_SUCCESS', res.data)
            resolve(true)
          })
          .catch(error => {
            commit('B2C_ERROR', { status: error.response.status, ...error.response.data })
            resolve(false)
          })
      })
    },
    B2C_ORDER_CREATE({ commit }, token) {
      return new Promise(resolve => {
        commit('B2C_IS_FETCHING')
        axios
          .post(process.env.VUE_APP_API_BASE_URL + '/public/orders/' + token)
          .then(res => {
            commit('ORDERS_GET_ITEM_SUCCESS', res.data)
            commit('B2C_IS_NOT_FETCHING')
            resolve()
          })
          .catch(error => {
            commit('ORDERS_ERROR', error.response.data.code)
            resolve(false)
          })
      })
    },
    B2C_ORDER_UPDATE({ commit }, payload) {
      return new Promise(resolve => {
        axios
          .put(process.env.VUE_APP_API_BASE_URL + '/public/orders/send/' + payload.id + '/' + payload.token, {
            lang: payload.lang,
            configuration: payload.configuration,
            quantity: payload.quantity || 1,
            notes: payload.notes || '',
            reference: payload.reference || '',
            consumerName: payload.consumerName || '',
            consumerEmail: payload.consumerEmail || '',
            consumerPhone: payload.consumerPhone || '',
            pdf: payload.pdf
          })
          .then(() => {
            commit('B2C_THANK_YOU')
            resolve()
          })
          .catch(error => {
            commit('ORDERS_ERROR', error.response.data.code)
            resolve()
          })
      })
    },
    B2C_IS_PROCESSING({ commit }, value = true) {
      commit('B2C_IS_PROCESSING', value)
    }
  }
}
