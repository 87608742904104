import Vue from 'vue'
import Vuex from 'vuex'

// Store modules
import auth from '@/store/modules/auth'
import dealers from '@/store/modules/dealers'
import settings from '@/store/modules/settings'
import orders from '@/store/modules/orders'
import configurator from '@/store/modules/configurator'
import logos from '@/store/modules/logos'
import b2c from '@/store/modules/b2c'
import sizeyou from '@/store/modules/sizeyou'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    dealers,
    settings,
    orders,
    configurator,
    logos,
    b2c,
    sizeyou
  }
})
