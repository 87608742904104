export default {
  // General
  PAGE_NOT_FOUND: 'Pagina Non Trovata',
  NO_ITEMS_FOUND: 'Nessun Elemento Presente',
  ERROR_GENERIC: 'Qualcosa è andato storto. Ricaricare',
  DELETE_CONFIRM_QUESTION: 'Eliminare?',
  YES: 'Si',
  NO: 'No',
  FROM: 'Da',
  TO: 'A',
  CREDITS: 'Crediti',
  UNIT_COST: 'Costo Unitario',
  CM: 'cm',
  PRICE: 'Prezzo',
  EXIT_SURE_QUESTION: 'Le modifiche apportate potrebbero non essere salvate.\nSicuro di uscire?',
  // Header
  HEADER_ORDERS_MENU: 'Ordini',
  HEADER_CUSTOMERS_MENU: 'Clienti',
  HEADER_PARAMETERS_MENU: 'Parametri',
  HEADER_LOGOUT: 'Esci',
  HEADER_DEALER_LINK_TXT: 'Questo è il link alla tua pagina negozio, condividilo con i tuoi clienti:',
  HEADER_B2B_ORDER: 'Ordini',
  HEADER_B2C_ORDER: 'Richieste web',
  // Login Page
  AUTH_ACCESS_DENIED: 'Username or Password invalid',
  AUTH_FORBIDDEN: 'Username or Password invalid',
  LOGIN_USERNAME: 'Username',
  LOGIN_PASSWORD: 'Password',
  LOGIN_SUBMIT: 'LOGIN',
  LOGIN_FORGOT_LINK: 'Reset Password',
  LOGIN_ERROR_USERNAME_REQUIRED: 'Username is required',
  LOGIN_ERROR_PASSWORD_REQUIRED: 'Password is required',
  LOGIN_RESET_TITLE: 'Insert your email address to receive the new password',
  LOGIN_RESET_EMAIL: 'Email Address',
  LOGIN_RESET_SUBMIT: 'Reset',
  LOGIN_RESET_BACK: 'Back to Login',
  LOGIN_RESET_ERROR_EMAIL_REQUIRED: 'Email Address is required',
  LOGIN_RESET_ERROR_EMAIL_INVALID: 'Email Address not valid',
  LOGIN_RESET_SUCCESS: 'An email with the new password has been sent',
  // Dashboard Page
  DASHBOARD_TITLE: 'Lista Ordini',
  DASHBOARD_TITLE_B2C: 'Lista Ordini Clienti',
  DASHBOARD_NEW_ORDER: 'Nuovo Ordine',
  DASHBOARD_ORDER_CODE: 'Codice',
  DASHBOARD_CUSTOMER_NAME: 'Nome Cliente',
  DASHBOARD_ORDER_DATE: 'Data',
  DASHBOARD_ORDER_STATUS: 'Stato',
  DASHBOARD_ORDER_QUANTITY: 'N°',
  DASHBOARD_ORDER_REFERENCE: 'Ref',
  DASHBOARD_ORDER_CREATED: 'Creato',
  DASHBOARD_ORDER_SENT: 'Inviato',
  DASHBOARD_ORDER_STATUS_NEW: 'Nuovo',
  DASHBOARD_ORDER_STATUS_IN_PROGRESS: 'In Corso',
  DASHBOARD_ORDER_STATUS_SENT: 'Inviato a Sparco',
  DASHBOARD_ORDER_STATUS_CLOSED: 'Chiuso',
  DASHBOARD_FILTER_TITLE_SEARCH: 'Ricerca',
  DASHBOARD_FILTER_TITLE_SETTINGS: 'Impostazioni',
  DASHBOARD_FILTER_BY_ORDER: 'Per Codice',
  DASHBOARD_FILTER_BY_CUSTOMER: 'Per Nome Cliente',
  DASHBOARD_FILTER_BY_DATE: 'Per Data',
  DASHBOARD_FILTER_BY_REF: 'Per REF',
  DASHBOARD_FILTER_BY_CONSUMER_NAME: 'Per Nome',
  DASHBOARD_FILTER_BY_CONSUMER_EMAIL: 'Per Email',
  DASHBOARD_FILTER_BY_CONSUMER_PHONE: 'Per Telefono',
  DASHBOARD_FILTER_PLACEHOLDER_ORDER: 'Inserisci qui il codice ordine',
  DASHBOARD_FILTER_PLACEHOLDER_CUSTOMER: 'Inserisci qui il nome cliente',
  DASHBOARD_FILTER_PLACEHOLDER_DATE: 'Seleziona Periodo',
  DASHBOARD_FILTER_PLACEHOLDER_REF: 'Inserisci qui il REF ordine',
  DASHBOARD_FILTER_PLACEHOLDER_CONSUMER_NAME: 'Inserisci qui il nome cliente',
  DASHBOARD_FILTER_PLACEHOLDER_CONSUMER_EMAIL: "Inserisci qui l'email cliente",
  DASHBOARD_FILTER_PLACEHOLDER_CONSUMER_PHONE: 'Inserisci qui il telefono cliente',
  DASHBOARD_FILTER_SHOW_SENT: 'Mostra ordini inviati',
  DASHBOARD_FILTER_SHOW_CLOSED: 'Mostra ordini chiusi',
  DASHBOARD_FILTER_RECAP_NO_FILTER: 'Nessun Filtro',
  DASHBOARD_FILTER_RECAP_PREFIX: 'Filtro',
  DASHBOARD_ORDER_CONSUMER_NAME: 'Nome',
  DASHBOARD_ORDER_CONSUMER_EMAIL: 'Email',
  DASHBOARD_ORDER_CONSUMER_PHONE: 'Telefono',
  DASHBOARD_DOWNLOAD_S3_MODAL: 'Il tuo download è pronto. Clicca per scaricare.',

  // Actions
  ACTION_SEARCH: 'Cerca',
  ACTION_DELETE: 'Elimina',
  ACTION_SEND: 'Invia',
  ACTION_VIEW: 'Vedi',
  ACTION_PRICE: 'Calcola Prezzo',
  ACTION_EXPORT: 'Esporta',
  ACTION_EDIT: 'Modifica',
  ACTION_CLONE: 'Clona',
  ACTION_CLOSE: 'Chiudi',
  ACTION_SAVE: 'Salva',
  ACTION_ROLLBACK: 'Rollback',
  ACTION_CANCEL: 'Annulla',
  ACTION_UPLOAD: 'Carica',
  ACTION_LINK: 'Link',
  ACTION_CONFIRM: 'Conferma',
  ACTION_CONSUMER_INFO: 'Info Cliente',
  ACTION_PREV: 'Precedente',
  ACTION_NEXT: 'Successivo',
  DASHBOARD_ORDER_CLOSE_CONFIRM_QUESTION: 'Desideri chiudere questo ordine?',
  DASHBOARD_ORDER_SEND_CONFIRM_QUESTION: 'Inviare questo ordine a Sparco?',
  DASHBOARD_ORDER_SEND_CONFIRM_PAYOFF_1:
    "L'invio della configurazione a Sparco non comporta automaticamente l'immissione di un ordine d'acquisto.",
  DASHBOARD_ORDER_SEND_CONFIRM_PAYOFF_2:
    "L'ordine sarà registrato come tale solo a seguito dell'approvazione da parte di Sparco e la consegna avverrà entro 4 settimane dalla registrazione dell'ordine.",
  DASHBOARD_ORDER_ROLLBACK_CONFIRM_QUESTION: "Desideri rimandare l'ordine al dealer {dealerName}?",
  DASHBOARD_ORDER_PRICE_TITLE: 'Preventivo Ordine n. {orderId}',
  DASHBOARD_ORDER_CONFIRM_TITLE: 'Conferma Ordine n. {orderId}',
  DASHBOARD_ORDER_CONFIRM_QUESTION: "Vuoi confermare l'ordine di questo cliente?",
  DASHBOARD_ORDER_CONFIRM_PAYOFF:
    'Al termine di questa operazione troverai questo ordine nella tua lista B2B, dove potrai revisionarlo e inviarlo a Sparco.',
  DASHBOARD_ORDER_DELETE_MULTIPLE_TITLE: 'Eliminare {orderCount} Ordini',
  DASHBOARD_ORDER_DELETE_MULTIPLE_QUESTION: 'Vuoi eliminare i seguenti ordini?',
  // Customers Page
  CUSTOMERS_TITLE: 'Lista Clienti',
  CUSTOMERS_NEW_USER: 'Nuovo Cliente',
  CUSTOMERS_CLOSE_PANEL: 'Chiudi',
  CUSTOMERS_CODE: 'Codice Cliente',
  CUSTOMERS_NAME: 'Nome Cliente',
  CUSTOMERS_EMAIL: 'Email',
  CUSTOMERS_CREATED: 'Creato',
  CUSTOMERS_COMPANY: 'Company',
  CUSTOMERS_DISCOUNT: 'Sconto (%)',
  CUSTOMERS_LOGO: 'Logo',
  CUSTOMERS_CODE_LABEL: 'Codice Cliente',
  CUSTOMERS_CODE_PLACEHOLDER: 'Inserisci qui il codice cliente',
  CUSTOMERS_NAME_LABEL: 'Nome Cliente',
  CUSTOMERS_NAME_PLACEHOLDER: 'Inserisci qui il nome cliente',
  CUSTOMERS_EMAIL_LABEL: 'Indirizzo Email',
  CUSTOMERS_EMAIL_PLACEHOLDER: "Inserisci qui l'indirizzo email",
  CUSTOMERS_DISCOUNT_LABEL: 'Percentuale di Sconto (0-100)',
  CUSTOMERS_DISCOUNT_PLACEHOLDER: 'Inserisci qui la percentuale di sconto',
  CUSTOMERS_PASSWORD_LABEL: 'Password',
  CUSTOMERS_PASSWORD_PLACEHOLDER: 'Inserisci qui la password',
  CUSTOMERS_CONFIRM_PASSWORD_LABEL: 'Conferma password',
  CUSTOMERS_CONFIRM_PASSWORD_PLACEHOLDER: 'Inserisci qui la conferma password',
  CUSTOMERS_COMPANY_LABEL: 'Company',
  CUSTOMERS_COMPANY_PLACEHOLDER: 'Inserisci qui la company',
  CUSTOMERS_CREATE_BTN: 'Crea Cliente',
  CUSTOMERS_EDIT_BTN: 'Modifica Cliente',
  CUSTOMERS_ERROR_CODE_REQUIRED: 'Codice cliente obbligatorio',
  CUSTOMERS_ERROR_NAME_REQUIRED: 'Nome cliente obbligatorio',
  CUSTOMERS_ERROR_EMAIL_REQUIRED: 'Indirizzo email obbligatorio',
  CUSTOMERS_ERROR_EMAIL_INVALID: 'Indirizzo email non valido',
  CUSTOMERS_ERROR_DISCOUNT_REQUIRED: 'Percentuale di sconto obbligatoria',
  CUSTOMERS_ERROR_DISCOUNT_INVALID: 'Percentuale di sconto non valida (0 - 100)',
  CUSTOMERS_ERROR_PASSWORD_REQUIRED: 'Password obbligatoria',
  CUSTOMERS_ERROR_CONFIRM_PASSWORD_INVALID: 'Conferma password non valida',
  CUSTOMERS_ERROR_CODE_UNIQUE: 'Un record con questo codice cliente è già presente',
  CUSTOMERS_ERROR_EMAIL_UNIQUE: 'Un record con questo indirizzo email è già presente',
  CUSTOMERS_ERROR_NAME_UNIQUE: 'Un record con questo nome cliente è già presente',
  CUSTOMERS_ERROR_COMPANY_REQUIRED: 'Company obbligatorio',
  CUSTOMERS_COMPANY_ITA: 'Sparco Italia',
  CUSTOMERS_COMPANY_USA: 'Sparco USA',
  CUSTOMERS_LOGO_LABEL: 'Logo Cliente (GIF, PNG, JPG - Max 500 KB)',
  CUSTOMERS_ERROR_LOGO_SIZE: 'Dimensione massima file: 500 KB',
  CUSTOMERS_ERROR_LOGO_MIME: 'File accettati: GIF, PNG, JPG',
  CUSTOMER_LANDING_LINK: 'Link Pagina Landing',
  CUSTOMER_CONFIGURATOR_LINK: 'Link Configuratore',
  CUSTOMERS_FILTER_TITLE_SEARCH: 'Ricerca Nome Cliente',
  CUSTOMERS_FILTER_PLACEHOLDER_NAME: 'Inserisci qui il nome cliente',
  CUSTOMERS_FILTER_RECAP_NO_FILTER: 'Nessun filtro',
  CUSTOMERS_FILTER_RECAP_PREFIX: 'Filtro',
  CUSTOMERS_FILTER_BY_NAME: 'Per Nome Cliente',
  CUSTOMERS_HIDE_DESCRIPTION_PRICE: 'Nascondi Prezzo Modello',

  // Parameters Page
  PARAMETERS_TITLE: 'Parametri',
  PARAMETERS_SURCHARGES_CATEGORY: 'Categeoria',
  PARAMETERS_SURCHARGES_ITEM: 'Oggetto',
  PARAMETERS_NAV_PRICING: 'Prezzo Loghi',
  PARAMETERS_NAV_MODELS: 'Prezzo Prodotti',
  PARAMETERS_NAV_SURCHARGES: 'Sovraprezzi',
  PARAMETERS_NAV_LOGOS: 'Libreria Loghi',
  PARAMETERS_PRICING_LOGO_TITLE: 'Prezzo Superficie Loghi',
  PARAMETERS_PRICING_DIGIT_TITLE: 'Prezzo Digitalizzazione',
  PARAMETERS_PRICING_ADD_RULE: 'Aggiungi Regola',
  PARAMETERS_PRICING_SAVE: 'Salva',
  PARAMETERS_MODELS_TITLE: 'Prezzo Prodotti',
  PARAMETERS_MODELS_SKU: 'SKU',
  PARAMETERS_MODELS_PRODUCT_NAME: 'Nome Prodotto',
  PARAMETERS_MODELS_PRODUCT_COMPANY: 'Company',
  PARAMETERS_PRICING_CREDIT_COST_TITLE: 'Prezzo Credito',
  PARAMETERS_PRICING_CREDIT_COST_MODE: 'Modalità',
  PARAMETERS_PRICING_CREDIT_COST_MODE_PRINT: 'Stampa',
  PARAMETERS_PRICING_CREDIT_COST_MODE_EMBROIDERY: 'Ricamo',
  PARAMETERS_PRICING_CREDIT_COST_PRICE: 'Prezzo',
  PARAMETERS_PRICING_INCLUDED_CREDIT_TITLE: 'Crediti Gratuiti',
  PARAMETERS_PRICING_INCLUDED_CREDIT_NUM: 'Numero crediti gratuiti',
  PARAMETERS_SIZES_STANDARD: 'Regolazioni Standard',
  PARAMETERS_SIZES_CUSTOM: 'Personalizzata',
  PARAMETERS_NASCAR_CUFFS: 'Gamba Nascar',
  PARAMETERS_UPLOAD_HINT: 'Carica immagine: jpg, png, GIF',
  PARAMETERS_LOG_TEXT_HINT: 'Inserisci etichetta:',
  PARAMETERS_LOG_TABLE_HEAD_LOGO: 'Logo',
  PARAMETERS_LOG_TABLE_HEAD_LABEL: 'Etichetta',
  // Configurator Page
  CFG_HEAD_EXIT: 'Esci',
  CFG_FOOT_SAVE_BTN: 'Vai al Riepilogo',
  CFG_FOOT_BACK_CONFIG: 'Torna al Configuratore',

  CFG_START_TITLE: "Let's start",
  CFG_START_INTRO_TXT: 'Seleziona il <strong>colore {colorType}</strong> per la tua tuta',
  CFG_START_INTRO_TXT_PRIMARY: 'primario',
  CFG_START_INTRO_TXT_SECONDARY: 'secondario',
  CFG_START_INTRO_TXT_SKIP: 'Inizia subito',

  CFG_MENU_DESIGN: 'Modello',
  CFG_MENU_STYLE: 'Stile',
  CFG_MENU_COLOR: 'Colore',
  CFG_MENU_TEXT: 'Testo',
  CFG_MENU_SIZE: 'Taglia',
  CFG_MENU_LOGO: 'Logo',
  CFG_MENU_SUMMARY: 'Riepilogo',

  CFG_DESIGN_TITLE_MODEL: 'Seleziona Modello',
  CFG_DESIGN_TITLE_STYLE: 'Seleziona Stile',
  CFG_DESIGN_TITLE_POCKET: 'Tasche',
  CFG_DESIGN_TITLE_NAVIGATOR_POCKET: 'Tasche Navigatore',
  CFG_DESIGN_TITLE_BELT: 'Cintura',
  CFG_DESIGN_TITLE_NASCAR_CUFFS: 'Gamba Nascar',
  CFG_DESIGN_PRICE_FROM: 'A partire da',

  CFG_AREA1_NAME: 'Area 1',
  CFG_AREA2_NAME: 'Area 2',
  CFG_AREA3_NAME: 'Area 3',
  CFG_AREA4_NAME: 'Area 4',
  CFG_JERSEY1_NAME: 'Jersey 1',
  CFG_JERSEY2_NAME: 'Jersey 2',
  CFG_POLSICAVIGLIE_NAME: 'Polsi e caviglie',
  CFG_POLSI_NAME: 'Polsi',
  CFG_CAVIGLIE_NAME: 'Caviglie',

  CFG_COLOR_TITLE_AREA: 'Seleziona Area',
  CFG_COLOR_TITLE_COLOR: 'Seleziona Colore',

  CFG_TEXT_TITLE_CUSTOMIZE: 'Personalizza i tuoi dati',
  CFG_TEXT_TITLE_COLOR: 'Seleziona Colore',
  CFG_TEXT_PLACEHOLDER_NAME: 'Nome',
  CFG_TEXT_PLACEHOLDER_FLAG: 'Nazione',
  CFG_TEXT_PLACEHOLDER_BLOOD: 'Gruppo Sanguigno',
  CFG_TEXT_WARNING_TITLE: 'Attenzione',
  CFG_TEXT_WARNING_TEXT:
    'su alcuni modelli il posizionamento degli elementi grafici potrebbe differire leggermente da quanto visualizzato per vincoli di produzione.',

  CFG_SIZE_TITLE_TYPE: 'Seleziona Tipologia',
  CFG_SIZE_STANDARD: 'Standard',
  CFG_SIZE_CUSTOMIZED: 'Personalizzata',
  CFG_SIZE_GENDER_MALE: 'Uomo',
  CFG_SIZE_GENDER_FEMALE: 'Donna',
  CFG_SIZE_TITLE_STANDARD: 'Misure Standard',
  CFG_SIZE_TITLE_REGULATE: 'Regola le misure',
  CFG_SIZE_TITLE_REGULATE_NOTE: 'Le regolazioni prevedono un supplemento',
  CFG_SIZE_TITLE_GET_YOUR: 'Prendi le tue misure',
  CFG_SIZE_TITLE_GET_YOUR_SOON_AVAILABLE: 'Funzionalità disponibile a breve',
  CFG_SIZE_TITLE_CUSTOMIZED: 'Misure Personalizzate',

  CFG_SIZE_PESO: 'Peso',
  CFG_SIZE_ALTEZZA: 'Altezza',
  CFG_SIZE_TORACE: 'A. Circonferenza Torace',
  CFG_SIZE_TORACE_DESCRIPTION:
    'Prendete un metro a nastro, fatelo passare sotto le ascelle e disponetelo sul punto più pronunciato del vostro petto in posizione orizzontale, tenendo le braccia lungo i fianchi.',
  CFG_SIZE_VITA: 'B. Circonferenza Vita',
  CFG_SIZE_VITA_DESCRIPTION:
    'Disponete il metro attorno al punto vita in posizione orizzontale, con le braccia lungo i fianchi. Chiudete il cerchio e indicate nella tabella il valore espresso in centimetri.',
  CFG_SIZE_BACINO: 'C. Circonferenza Bacino',
  CFG_SIZE_BACINO_DESCRIPTION:
    'Posizionate il metro attorno ai fianchi nel punto più largo, le braccia devono essere distese lungo i fianchi. Per darvi una informazione aggiuntiva, posizionate il metro a circa 18-20 cm sotto il punto vita.',
  CFG_SIZE_COSCIA: 'D. Circonferenza Coscia',
  CFG_SIZE_COSCIA_DESCRIPTION:
    'Disponete il metro orizzontalmente attorno alla coscia, cercando di prendere il punto più largo della gamba: dovrebbe essere a circa 10 cm dal cavallo.',
  CFG_SIZE_SPALLE: 'E. Larghezza Spalle',
  CFG_SIZE_SPALLE_DESCRIPTION:
    'Con le braccia lungo i fianchi, tendere il metro orizzontalmente da osso ad osso. È importante avere il metro teso per avere una corretta misura.',
  CFG_SIZE_MANICA: 'F. Lunghezza Manica',
  CFG_SIZE_MANICA_DESCRIPTION:
    "Iniziate la misurazione partendo dall'osso della spalla cercando di ruotare il metro sul gomito, a braccio flesso, fino all'osso del polso.",
  CFG_SIZE_LARGHEZZA_GAMBA: 'G. Lunghezza busto davanti',
  CFG_SIZE_GAMBA_DESCRIPTION:
    "Prendere la misura partendo dall'incavo del collo fino alla parte anteriore del cavallo (riferimento cucitura del cavallo dell'underwear). E' fondamentale mettere il metro nella posizione corretta, tenendolo sempre in tensione in posizione verticale e non piegando il busto.",
  CFG_SIZE_GAMBA_ESTERNO: 'H. Lunghezza Esterno Gamba',
  CFG_SIZE_GAMBA_ESTERNO_DESCRIPTION:
    "Posizionate il metro lateralmente, e fate scorrere il metro dalla vita fino all'osso della caviglia.",
  CFG_SIZE_GOMITO: 'L. Lunghezza interno gamba',
  CFG_SIZE_GOMITO_DESCRIPTION:
    "Tenete il metro nella parte interna della gamba, facendo attenzione a farlo scorrere dal cavallo fino all'osso interno della caviglia.",
  CFG_SIZE_DIETRO: 'M. Lunghezza Dietro',
  CFG_SIZE_DIETRO_DESCRIPTION:
    'Posizionare il metro partendo dalla vertebra inferiore della nuca, farlo scorrere per tutta la spina dorsale fino al punto vita. (vedi punto B)',
  CFG_SIZE_CAVALLO_DIETRO: 'N. Circonferenza collo',
  CFG_SIZE_CAVALLO_DIETRO_DESCRIPTION:
    'Disponete il metro attorno al collo e chiudete il cerchio. Indicate nello spazio il valore espresso in centimenti.',
  CFG_SIZE_BICIPITE: 'O. Circonferenza Bicipite',
  CFG_SIZE_BICIPITE_DESCRIPTION:
    'Disponete il metro attorno al punto più largo del bicipite. Chiudete il cerchio e indicate nella tabella il valore espresso in centimetri.',
  CFG_SIZE_FONDO_MANICA: 'P. Lunghezza totale cavallo',
  CFG_SIZE_FONDO_MANICA_DESCRIPTION:
    "Prendete il metro e mettetelo davanti al corpo. La misura dev'essere presa partendo dall'incavo del collo fino alla vertebra inferiore della nuca, passando dal cavallo. Il metro deve quindi fare il giro completo dalla parte frontale alla parte posteriore.",
  CFG_SIZE_CIRCONFERENZA_POLPACCIO: 'Q. Circonferenza Polpaccio',
  CFG_SIZE_CIRCONFERENZA_POLPACCIO_DESCRIPTION: 'Far passare il metro intorno al punto più ampio del polpaccio.',
  CFG_SIZE_COLLO_ALLA_VITA_FRONTE: 'R. Lunghezza Torso-vita',
  CFG_SIZE_COLLO_ALLA_VITA_FRONTE_DESCRIPTION: `Partire a misurare dall'incavo del collo fino alla vita.`,
  CFG_SIZE_CIRCONFERENZA_AVAMBRACCIO: 'S. Circonferenza avambraccio',
  CFG_SIZE_CIRCONFERENZA_AVAMBRACCIO_DESCRIPTION: `Far passare il metro nel punto più ampio dell'avambraccio.`,
  CFG_SIZE_CIRCONFERENZA_POLSO: 'T. Circonferenza Polso',
  CFG_SIZE_CIRCONFERENZA_POLSO_DESCRIPTION: `Far passare il metro attorno l'osso del polso.`,
  CFG_SIZE_LARGHEZZA_TORACE: 'U. Larghezza Torace',
  CFG_SIZE_LARGHEZZA_TORACE_DESCRIPTION: `Braccia lungo i fianchi, far passare il metro da un'ascella all'altra.`,
  CFG_SIZE_LARGHEZZA_SCHIENALE: 'V. Larghezza Schiena',
  CFG_SIZE_LARGHEZZA_SCHIENALE_DESCRIPTION: `Braccia lungo i fianchi, far passare il metro da un'ascella all'altra.`,
  CFG_SIZE_CIRCONFERENZA_CAVIGLIA: 'Z. Circonferenza Caviglia',
  CFG_SIZE_CIRCONFERENZA_CAVIGLIA_DESCRIPTION: `Far passare il metro attorno l'osso della caviglia.`,

  CFG_SIZE_STD_SUFFIX_XS: '',
  'CFG_SIZE_STD_SUFFIX_S/M': '',
  'CFG_SIZE_STD_SUFFIX_L/XL': '',
  CFG_SIZE_STD_SUFFIX_XXL: '',
  CFG_SIZE_STD_SUFFIX_48: '',
  CFG_SIZE_STD_SUFFIX_50: '',
  CFG_SIZE_STD_SUFFIX_52: '',
  CFG_SIZE_STD_SUFFIX_54: '',
  CFG_SIZE_STD_SUFFIX_56: '',
  CFG_SIZE_STD_SUFFIX_58: '',
  CFG_SIZE_STD_SUFFIX_60: '',
  CFG_SIZE_STD_SUFFIX_62: '',
  CFG_SIZE_STD_SUFFIX_64: '',
  CFG_SIZE_STD_SUFFIX_66: '',
  CFG_SIZE_STD_SUFFIX_120: ' - Bambino',
  CFG_SIZE_STD_SUFFIX_130: ' - Bambino',
  CFG_SIZE_STD_SUFFIX_140: ' - Bambino',
  CFG_SIZE_STD_SUFFIX_150: ' - Bambino',

  CFG_LOGO_PETTO: 'Loghi Petto',
  CFG_LOGO_GAMBE: 'Loghi Gambe',
  CFG_LOGO_MANICASX: 'Loghi Manica Sx',
  CFG_LOGO_MANICADX: 'Loghi Manica Dx',
  CFG_LOGO_SCHIENA: 'Loghi Schiena',
  CFG_LOGO_TITLE_SELECT: 'Seleziona Area',
  CFG_LOGO_TITLE_UPLOAD: 'Carica Loghi',
  CFG_LOGO_TITLE_MODE: 'Modalità',
  CFG_LOGO_TITLE_MODE_PRINT: 'Stampa',
  CFG_LOGO_TITLE_MODE_EMBROIDERY: 'Ricamo',
  CFG_LOGO_UPLOAD_HINT: 'Carica<br>jpg, png, GIF',
  CFG_LOGO_LIBRARY: 'Libreria',
  CFG_LOGO_LIBRARY_NO_ELEMS: 'Nessun logo presente nella libreria',
  CFG_LOGO_LIBRARY_NO_ELEM_BY_FILTER: 'Nessun risultato trovato',
  CFG_LOGO_WARNING_HINT: 'Leggi<br>le avvertenze',
  CFG_LOGO_WARNING_TITLE: 'Attenzione',
  CFG_LOGO_INSTRUCTION:
    "<b>Per la produzione dell'ordine sarà necessario inviare i loghi in formato vettoriale (AI o EPS) successivamente all'inserimento dell'ordine.</b><br><br>Per ottenere il miglior risultato finale, SPARCO potrebbe decidere di effettuare un aggiustamento di loghi come posizionamento e dimensionamento, anche se queste non influenzeranno significativamente lo stile della vostra creazione. Si consiglia di non inserire loghi su un'area con colore multiplo; possiamo eseguire il lavoro, ma risultato potrebbe essere imperfetto.",
  CFG_LOGO_INSTRUCTION_UNDERWEAR:
    "<b>Per la produzione dell'ordine sarà necessario inviare i loghi in formato vettoriale (AI o EPS) successivamente all'inserimento dell'ordine.</b><br><br>Per ottenere il miglior risultato finale, SPARCO potrebbe decidere di effettuare un aggiustamento di loghi come posizionamento e dimensionamento, anche se queste non influenzeranno significativamente lo stile della vostra creazione.",
  CFG_LOGO_NUM_LOGOS: 'Loghi inseriti',
  CFG_LOGO_CREDIT: 'Crediti utilizzati',
  CFG_LOGO_PRICE: 'Prezzo',
  CFG_ERROR_FILENOTSUPPORTED: 'File non supportato',
  CFG_ERROR_FILESIZE: "L'immagine supera la dimensione massima consentita",
  CFG_ERROR_GENERIC: 'Errore nel caricamento',

  CFG_SUMMARY_CONFIGURATION: 'Prezzo Base',
  CFG_SUMMARY_MEASURES: 'Sovraprezzo Taglie',
  CFG_SUMMARY_LOGOS: 'Loghi',
  CFG_SUMMARY_DIGITALIZATION: 'Digitalizzazione',
  CFG_SUMMARY_PRICE: 'Totale',
  CFG_SUMMARY_TABLE_MODEL: 'Modello',
  CFG_SUMMARY_TABLE_STYLE: 'Stile',
  CFG_SUMMARY_TABLE_POCKET: 'Tasche',
  CFG_SUMMARY_TABLE_NAVIGATOR_POCKET: 'Tasche Navigatore',
  CFG_SUMMARY_TABLE_BELT: 'Cintura',
  CFG_SUMMARY_TABLE_NASCAR_CUFFS: 'Gamba Nascar',
  CFG_SUMMARY_TABLE_COLORS: 'Colori',
  CFG_SUMMARY_TABLE_TEXT: 'Testo',
  CFG_SUMMARY_TABLE_TEXT_COUNTRY: 'Nazione',
  CFG_SUMMARY_TABLE_TEXT_BLOOD: 'Gruppo Sanguigno',
  CFG_SUMMARY_TABLE_TEXT_NAME: 'Nome',
  CFG_SUMMARY_TABLE_TEXT_COLOR: 'Colore Testo',
  CFG_SUMMARY_TABLE_SIZE: 'Taglia',
  CFG_SUMMARY_TABLE_SIZE_STD: 'Standard',
  CFG_SUMMARY_TABLE_SIZE_CUSTOM: 'Personalizzata',
  CFG_SUMMARY_TABLE_SIZE_REGULATIONS: 'Regolazioni',
  CFG_SUMMARY_TABLE_SIZE_MEASURES: 'Misure',
  CFG_SUMMARY_TABLE_LOGOS: 'Loghi',
  CFG_SUMMARY_TABLE_LOGOS_NUM: 'Loghi inseriti',
  CFG_SUMMARY_TABLE_LOGOS_CREDIT: 'Crediti utilizzati',
  CFG_SUMMARY_TABLE_LOGOS_MODE: 'Modalità',
  CFG_SUMMARY_TABLE_LOGOS_MODE_PRINT: 'Stampa',
  CFG_SUMMARY_TABLE_LOGOS_MODE_EMBROIDERY: 'Ricamo',
  CFG_SUMMARY_QUANTITY: 'Quantità',
  CFG_SUMMARY_NOTES: 'Note',
  CFG_SUMMARY_REF: 'REF',
  CFG_SUMMARY_CONSUMER_NAME: 'Nome Completo',
  CFG_SUMMARY_CONSUMER_EMAIL: 'Email',
  CFG_SUMMARY_CONSUMER_PHONE: 'Telefono',
  CFG_SUMMARY_SAVE: 'Salva',
  CFG_SUMMARY_SAVE_EXIT: 'Salva ed Esci',
  CFG_SUMMARY_SAVE_ERROR: "Impossibile completare l'operazione.",
  CFG_SUMMARY_VALIDATION_ERROR_SIZE: 'Completare tutte le misure.',
  CFG_SUMMARY_VALIDATION_ERROR_QTY: 'Quantità non valida.',
  CFG_SUMMARY_VALIDATION_ERROR_CONSUMER_NAME: 'Nome Completo obbligatorio.',
  CFG_SUMMARY_VALIDATION_ERROR_CONSUMER_EMAIL: 'Indirizzo Email non valido.',
  CFG_SUMMARY_VALIDATION_ERROR_CONSUMER_PHONE: 'Numero di Telefono non valido.',
  CFG_SUMMARY_B2C_SEND: 'Invia Richiesta<br class="d-xl-none" /> d\'Ordine',
  CFG_SUMMARY_B2C_SEND_PROCESS_TXT_1: 'Stiamo processando il tuo ordine.',
  CFG_SUMMARY_B2C_SEND_PROCESS_TXT_2: 'A breve riceverai una mail di conferma.',
  CFG_SUMMARY_B2C_SEND_PROCESS_TXT_3: 'Non chiudere la finestra del browser durante le operzioni.',
  CFG_SUMMARY_B2C_REQUIRED_FIELDS: 'Campi Obbligatori.',

  CFG_B2C_THANK_YOU_TITLE: 'Grazie',
  CFG_B2C_THANK_YOU_TEXT: 'Il tuo ordine è stato inviato correttamente.',

  PDF_MODEL: 'Modello',
  PDF_STYLE: 'Stile',
  PDF_SKU: 'SKU',
  PDF_ORDER_CODE: 'Codice Ordine',
  PDF_ORDER_DATA_TITLE: 'Dati Ordine',
  PDF_ORDER_DATA_QUANTITY: 'Quantità',
  PDF_ORDER_DATA_NOTES: 'Note',
  PDF_ORDER_DATA_REF: 'REF',
  PDF_AREAS_TITLE: 'Aree',
  PDF_AREA_NAME: 'Nome Area',
  PDF_AREA_MAT: 'Nome Materiale',
  PDF_AREA_CODE_MAT: 'Codice Materiale',
  PDF_TEXT_TITLE: 'Testo e Nazione',
  PDF_LOGOS: 'Loghi',
  PDF_LOGOS_MODE: 'Modalità',
  PDF_LOGOS_MODE_PRINT: 'Stampa',
  PDF_LOGOS_MODE_EMBROIDERY: 'Ricamo',
  PDF_LOGOS_NUM: 'Loghi inseriti',
  PDF_LOGOS_CREDIT: 'Crediti utilizzati',
  PDF_ACCESSORIES_TITLE: 'Accessori',
  PDF_POCKET_TITLE: 'Tasche',
  PDF_NAVIGATOR_POCKET_TITLE: 'Tasche Navigatore',
  PDF_BELT_TITLE: 'Cintura',
  PDF_NASCAR_CUFFS_TITLE: 'Gamba Nascar',
  PDF_TEXT_COUNTRY: 'Nazione',
  PDF_TEXT_NAME: 'Nome',
  PDF_TEXT_BLOOD: 'Gruppo Sanguigno',
  PDF_TEXT_FONT: 'Font',
  PDF_TEXT_COLOR: 'Colore Testo',
  PDF_SIZE_TITLE: 'Taglia',
  PDF_SIZE_STD: 'Standard',
  PDF_SIZE_CUSTOM: 'Personalizzata',
  PDF_SIZE_PART: 'Parte',
  PDF_SIZE_DIMENSION_STD: 'Regolazioni',
  PDF_SIZE_DIMENSION_CUSTOM: 'Misure',

  SIZEYOU_USERNAME: 'Username Cliente',
  SIZEYOU_ERROR_TITLE: 'Errore',
  SIZEYOU_ERROR_DESC: 'Chiudi il popup e riprova.',
  CFG_SUMMARY_SIZEYOU_USER: 'Utente SizeYou',

  // NEW LABELS - post invio per traduzione francese
  INFO_TOOLTIP: 'Dettagli',
  SIZEYOU_INSTRUCTIONS_1: "Scarica l'App SizeYou Professional",
  SIZEYOU_INSTRUCTIONS_2: 'Scatta due foto seguendo il tutorial',
  SIZEYOU_INSTRUCTIONS_3: 'Invia le tue misure a Sparco per completare il tuo ordine',
  CFG_SUMMARY_HOMOLOGATION: 'Omologazione',
  PDF_HOMOLOGATION: 'Omologazione',
  PARAMETERS_PRODUCT_DISABLED: 'Prodotto non attivo',
  OUT_OF_CATALOG: 'Fuori Catalogo',
  CFG_AREA5_NAME: 'Area 5',
  CFG_JERSEY3_NAME: 'Jersey 3',
  CATALOG_CHANGE_WARNING:
    "ATTENZIONE: con l'entrata in vigore del nuovo Catalogo 2022, gli ordini precedentemente creati in bozza e non ancora inviati a Sparco non saranno più gestibili. Sarà pertanto necessario creare altri ordini sulla base del nuovo catalogo."
}
