export default {
  CFG_COLOR_TESTO_BIANCO: 'Bianco',
  CFG_COLOR_TESTO_GIALLO: 'Giallo',
  CFG_COLOR_TESTO_ARANCIO: 'Arancio',
  CFG_COLOR_TESTO_ROSSO: 'Rosso',
  CFG_COLOR_TESTO_CELESTE: 'Celeste',
  CFG_COLOR_TESTO_AZZURRO_SPARCO: 'Azzurro Sparco',
  CFG_COLOR_TESTO_BLU_MARINE: 'Blu Marine',
  CFG_COLOR_TESTO_ARGENTO: 'Argento',
  CFG_COLOR_TESTO_GRIGIO_SCURO: 'Grigio Scuro',
  CFG_COLOR_TESTO_NERO: 'Nero',
  CFG_COLOR_TESTO_BLU_ELETTRICO: 'Blu Elettrico',

  CFG_COLOR_POLSICAVIGLIE_AZZURRO_SPARCO: 'Azzurro Sparco',
  CFG_COLOR_POLSICAVIGLIE_BLU_MARINE: 'Blu Marine',
  CFG_COLOR_POLSICAVIGLIE_NERO: 'Nero',
  CFG_COLOR_POLSICAVIGLIE_ROSSO: 'Rosso',
  CFG_COLOR_POLSICAVIGLIE_BIANCO: 'Bianco',
  CFG_COLOR_POLSICAVIGLIE_GRIGIO_423C: 'Grigio 423 C',

  CFG_COLOR_SUPERSPEED_PRIMARIO_BIANCO: 'Bianco',
  CFG_COLOR_SUPERSPEED_PRIMARIO_BLU_REFLEX: 'Blu Reflex',
  CFG_COLOR_SUPERSPEED_PRIMARIO_NERO: 'Nero',
  CFG_COLOR_SUPERSPEED_PRIMARIO_ROSSO: 'Rosso',
  CFG_COLOR_SUPERSPEED_SOVRAPPOSIZIONE_BLU_MARINE: 'Blu Marine',
  CFG_COLOR_SUPERSPEED_SOVRAPPOSIZIONE_ARANCIO_FLUO: 'Arancio Fluo',
  CFG_COLOR_SUPERSPEED_SOVRAPPOSIZIONE_CELESTE: 'Celeste',
  CFG_COLOR_SUPERSPEED_SOVRAPPOSIZIONE_FUCSIA: 'Fucsia',
  CFG_COLOR_SUPERSPEED_SOVRAPPOSIZIONE_GIALLO: 'Giallo',
  CFG_COLOR_SUPERSPEED_SOVRAPPOSIZIONE_GRIGIO_CHIARO: 'Grigio Chiaro',
  CFG_COLOR_SUPERSPEED_SOVRAPPOSIZIONE_GRIGIO_423C: 'Grigio 423 C',
  CFG_COLOR_SUPERSPEED_SOVRAPPOSIZIONE_GRIGIO_SCURO: 'Grigio Scuro',
  CFG_COLOR_SUPERSPEED_SOVRAPPOSIZIONE_VERDE_METAL: 'Verde Metal',
  CFG_COLOR_SUPERSPEED_SOVRAPPOSIZIONE_VERDE_BANDIERA: 'Verde Bandiera',
  CFG_COLOR_SUPERSPEED_SOVRAPPOSIZIONE_VERDE_LOTUS: 'Verde Lotus',
  CFG_COLOR_SUPERSPEED_SOVRAPPOSIZIONE_VENICE_BORDEAUX: 'Venice Bordeaux',
  CFG_COLOR_SUPERSPEED_JERSEY_BIANCO: 'Bianco',
  CFG_COLOR_SUPERSPEED_JERSEY_GRIGIO_NEBBIA: 'Grigio Nebbia',
  CFG_COLOR_SUPERSPEED_JERSEY_GRIGIO_CHIARO: 'Grigio Chiaro',
  CFG_COLOR_SUPERSPEED_JERSEY_NERO: 'Nero',

  CFG_COLOR_SPRINT_PRIMARIO_AZZURRO: 'Azzurro',
  CFG_COLOR_SPRINT_PRIMARIO_BLU_MARINE: 'Blu Marine',
  CFG_COLOR_SPRINT_PRIMARIO_BIANCO: 'Bianco',
  CFG_COLOR_SPRINT_PRIMARIO_GIALLO_FLUO: 'Giallo Fluo',
  CFG_COLOR_SPRINT_PRIMARIO_GRIGIO_423C: 'Grigio 423 C',
  CFG_COLOR_SPRINT_PRIMARIO_NERO: 'Nero',
  CFG_COLOR_SPRINT_PRIMARIO_PINK: 'Pink',
  CFG_COLOR_SPRINT_PRIMARIO_ROSSO_CITROEN: 'Rosso Citroen',
  CFG_COLOR_SPRINT_PRIMARIO_VERDE_FLUO: 'Verde Fluo',
  CFG_COLOR_SPRINT_SOVRAPPOSIZIONE_ARANCIO_FLUO: 'Arancio Fluo',
  CFG_COLOR_SPRINT_SOVRAPPOSIZIONE_CELESTE: 'Celeste',
  CFG_COLOR_SPRINT_SOVRAPPOSIZIONE_FUCSIA: 'Fucsia',
  CFG_COLOR_SPRINT_SOVRAPPOSIZIONE_GIALLO: 'Giallo',
  CFG_COLOR_SPRINT_SOVRAPPOSIZIONE_GRIGIO_CHIARO: 'Grigio Chiaro',
  CFG_COLOR_SPRINT_SOVRAPPOSIZIONE_GRIGIO_SCURO: 'Grigio Scuro',
  CFG_COLOR_SPRINT_SOVRAPPOSIZIONE_VERDE_BANDIERA: 'Verde Bandiera',
  CFG_COLOR_SPRINT_SOVRAPPOSIZIONE_VERDE_LOTUS: 'Verde Lotus',
  CFG_COLOR_SPRINT_SOVRAPPOSIZIONE_VENICE_BORDEAUX: 'Venice Bordeaux',
  CFG_COLOR_SPRINT_JERSEY_ARANCIO: 'Arancio',
  CFG_COLOR_SPRINT_JERSEY_AZZURRO: 'Azzurro',
  CFG_COLOR_SPRINT_JERSEY_BLU_MARINE: 'Blu Marine',
  CFG_COLOR_SPRINT_JERSEY_GIALLO: 'Giallo',
  CFG_COLOR_SPRINT_JERSEY_GRIGIO_CHIARO: 'Grigio Chiaro',
  CFG_COLOR_SPRINT_JERSEY_GRIGIO_423C: 'Grigio 423 C',
  CFG_COLOR_SPRINT_JERSEY_GRIGIO_SCURO: 'Grigio Scuro',
  CFG_COLOR_SPRINT_JERSEY_NERO: 'Nero',
  CFG_COLOR_SPRINT_JERSEY_ROSSO: 'Rosso',
  CFG_COLOR_SPRINT_JERSEY_VERDE_BANDIERA: 'Verde Bandiera',
  CFG_COLOR_SPRINT_JERSEY_BIANCO: 'Bianco',

  CFG_COLOR_PRIME_PRIMARIO_ARANCIO_166C: 'Arancio 166 C',
  CFG_COLOR_PRIME_PRIMARIO_ARANCIO_FLAME_ORANGE: 'Arancio Flame Orange',
  CFG_COLOR_PRIME_PRIMARIO_AZZURRO_SPARCO: 'Azzurro Sparco',
  CFG_COLOR_PRIME_PRIMARIO_BLU_MARINE: 'Blu Marine',
  CFG_COLOR_PRIME_PRIMARIO_BIANCO_OTTICO: 'Bianco Ottico',
  CFG_COLOR_PRIME_PRIMARIO_CELESTE: 'Celeste',
  CFG_COLOR_PRIME_PRIMARIO_GIALLO: 'Giallo',
  CFG_COLOR_PRIME_PRIMARIO_ANTRACITE: 'Antracite',
  CFG_COLOR_PRIME_PRIMARIO_GRIGIO_CHIARO: 'Grigio Chiaro',
  CFG_COLOR_PRIME_PRIMARIO_GRIGIO_SCURO: 'Grigio Scuro',
  CFG_COLOR_PRIME_PRIMARIO_GRIGIO_423C: 'Grigio 423 C',
  CFG_COLOR_PRIME_PRIMARIO_NERO: 'Nero',
  CFG_COLOR_PRIME_PRIMARIO_ROSSO: 'Rosso',
  CFG_COLOR_PRIME_PRIMARIO_VERDE_LOTUS: 'Verde Lotus',
  CFG_COLOR_PRIME_PRIMARIO_VERDE_AMR: 'Verde AMR',
  CFG_COLOR_PRIME_SOVRAPPOSIZIONE_ARANCIO_FLUO: 'Arancio Fluo',
  CFG_COLOR_PRIME_SOVRAPPOSIZIONE_FUCSIA: 'Fucsia',
  CFG_COLOR_PRIME_SOVRAPPOSIZIONE_GIALLO: 'Giallo',
  CFG_COLOR_PRIME_SOVRAPPOSIZIONE_VERDE_METAL: 'Verde Metal',
  CFG_COLOR_PRIME_SOVRAPPOSIZIONE_VERDE_BANDIERA: 'Verde Bandiera',
  CFG_COLOR_PRIME_SOVRAPPOSIZIONE_VENICE_BORDEAUX: 'Venice Bordeaux',
  CFG_COLOR_PRIME_JERSEY_ARANCIO: 'Arancio',
  CFG_COLOR_PRIME_JERSEY_AZZURRO: 'Azzurro',
  CFG_COLOR_PRIME_JERSEY_BLU_MARINE: 'Blu Marine',
  CFG_COLOR_PRIME_JERSEY_GIALLO: 'Giallo',
  CFG_COLOR_PRIME_JERSEY_GRIGIO_CHIARO: 'Grigio Chiaro',
  CFG_COLOR_PRIME_JERSEY_GRIGIO_423C: 'Grigio 423 C',
  CFG_COLOR_PRIME_JERSEY_GRIGIO_SCURO: 'Grigio Scuro',
  CFG_COLOR_PRIME_JERSEY_NERO: 'Nero',
  CFG_COLOR_PRIME_JERSEY_ROSSO: 'Rosso',
  CFG_COLOR_PRIME_JERSEY_VERDE_BANDIERA: 'Verde Bandiera',
  CFG_COLOR_PRIME_JERSEY_BIANCO: 'Bianco',
  CFG_COLOR_PRIME_PRIMARIO_BLU_CHIARO: 'Blu Chiaro',
  CFG_COLOR_PRIME_PRIMARIO_GRIGIO_7545C: 'Grigio 7545 C',
  CFG_COLOR_PRIME_SOVRAPPOSIZIONE_GIALLO_CEDRO: 'Giallo Cedro',
  CFG_COLOR_PRIME_SOVRAPPOSIZIONE_ROSSO_VODAFONE: 'Rosso Vodafone',

  CFG_COLOR_PRIME_2022_PRIMARIO_ARANCIO_166C: 'Arancio 166 C',
  CFG_COLOR_PRIME_2022_PRIMARIO_ARANCIO_FLAME_ORANGE: 'Arancio Flame Orange',
  CFG_COLOR_PRIME_2022_PRIMARIO_AZZURRO_SPARCO: 'Azzurro Sparco',
  CFG_COLOR_PRIME_2022_PRIMARIO_BLU_CHIARO: 'Blu Chiaro',
  CFG_COLOR_PRIME_2022_PRIMARIO_BLU_MARINE: 'Blu Marine',
  CFG_COLOR_PRIME_2022_PRIMARIO_BIANCO_OTTICO: 'Bianco Ottico',
  CFG_COLOR_PRIME_2022_PRIMARIO_CELESTE: 'Celeste',
  CFG_COLOR_PRIME_2022_PRIMARIO_GIALLO: 'Giallo',
  CFG_COLOR_PRIME_2022_PRIMARIO_GRIGIO_ANTRACITE: 'Grigio Antracite',
  CFG_COLOR_PRIME_2022_PRIMARIO_GRIGIO_423C: 'Grigio 423 C',
  CFG_COLOR_PRIME_2022_PRIMARIO_GRIGIO_CHIARO: 'Grigio Chiaro',
  CFG_COLOR_PRIME_2022_PRIMARIO_GRIGIO_SCURO: 'Grigio Scuro',
  CFG_COLOR_PRIME_2022_PRIMARIO_NERO: 'Nero',
  CFG_COLOR_PRIME_2022_PRIMARIO_ROSSO_SCURO: 'Rosso Scuro',
  CFG_COLOR_PRIME_2022_PRIMARIO_ROSSO: 'Rosso',
  CFG_COLOR_PRIME_2022_PRIMARIO_GIALLO_FLUO: 'Giallo Fluo',
  CFG_COLOR_PRIME_2022_PRIMARIO_VERDE_AMR: 'Verde AMR',
  CFG_COLOR_PRIME_2022_PRIMARIO_METAL_GREEN: 'Verde Metal',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_ARANCIO_166C: 'Arancio 166 C',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_ARANCIO_FLAME_ORANGE: 'Arancio Flame Orange',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_AZZURRO_SPARCO: 'Azzurro Sparco',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_BLU_CHIARO: 'Blu Chiaro',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_BLU_MARINE: 'Blu Marine',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_BIANCO_OTTICO: 'Bianco Ottico',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_CELESTE: 'Celeste',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_GIALLO: 'Giallo',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_GRIGIO_ANTRACITE: 'Grigio Antracite',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_GRIGIO_423C: 'Grigio 423 C',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_GRIGIO_CHIARO: 'Grigio Chiaro',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_GRIGIO_SCURO: 'Grigio Scuro',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_NERO: 'Nero',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_ROSSO_SCURO: 'Rosso Scuro',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_ROSSO: 'Rosso',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_GIALLO_FLUO: 'Giallo Fluo',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_VERDE_AMR: 'Verde AMR',
  CFG_COLOR_PRIME_2022_PRIMARIO_KIT_METAL_GREEN: 'Verde Metal',
  CFG_COLOR_PRIME_2022_JERSEY1_AZZURRO: 'Azzurro',
  CFG_COLOR_PRIME_2022_JERSEY1_GRIGIO_423C: 'Grigio 423 C',
  CFG_COLOR_PRIME_2022_JERSEY1_BIANCO: 'Bianco',
  CFG_COLOR_PRIME_2022_JERSEY2_BIANCO: 'Bianco',
  CFG_COLOR_PRIME_2022_JERSEY2_NERO: 'Nero',
  CFG_COLOR_PRIME_2022_JERSEY2_AZZURRO: 'Azzurro',

  CFG_COLOR_COMPETITION_PRIMARIO_ARANCIO_FLUO: 'Arancio Fluo',
  CFG_COLOR_COMPETITION_PRIMARIO_AZZURRO_SPARCO: 'Azzurro Sparco',
  CFG_COLOR_COMPETITION_PRIMARIO_BLU_MARINE: 'Blu Marine',
  CFG_COLOR_COMPETITION_PRIMARIO_BIANCO_OTTICO: 'Bianco Ottico',
  CFG_COLOR_COMPETITION_PRIMARIO_BLU_REFLEX: 'Blu Reflex',
  CFG_COLOR_COMPETITION_PRIMARIO_CELESTE: 'Celeste',
  CFG_COLOR_COMPETITION_PRIMARIO_FUCSIA: 'Fucsia',
  CFG_COLOR_COMPETITION_PRIMARIO_GIALLO: 'Giallo',
  CFG_COLOR_COMPETITION_PRIMARIO_GRIGIO_423C: 'Grigio 423 C',
  CFG_COLOR_COMPETITION_PRIMARIO_GRIGIO_CHIARO: 'Grigio Chiaro',
  CFG_COLOR_COMPETITION_PRIMARIO_GRIGIO_SCURO: 'Grigio Scuro',
  CFG_COLOR_COMPETITION_PRIMARIO_VERDE_METAL: 'Verde Metal',
  CFG_COLOR_COMPETITION_PRIMARIO_NERO: 'Nero',
  CFG_COLOR_COMPETITION_PRIMARIO_ROSSO: 'Rosso',
  CFG_COLOR_COMPETITION_PRIMARIO_VERDE_BANDIERA: 'Verde Bandiera',
  CFG_COLOR_COMPETITION_PRIMARIO_VERDE_LOTUS: 'Verde Lotus',
  CFG_COLOR_COMPETITION_PRIMARIO_VENICE_BORDEAUX: 'Venice Bordeaux',
  CFG_COLOR_COMPETITION_PRIMARIO_GIALLO_LIMONE: 'Giallo limone',
  CFG_COLOR_COMPETITION_PRIMARIO_GRIGIO_431C: 'Grigio 431 C',
  CFG_COLOR_COMPETITION_JERSEY_ARANCIO: 'Arancio',
  CFG_COLOR_COMPETITION_JERSEY_AZZURRO: 'Azzurro Sparco',
  CFG_COLOR_COMPETITION_JERSEY_BLU_MARINE: 'Blu Marine',
  CFG_COLOR_COMPETITION_JERSEY_GIALLO: 'Giallo',
  CFG_COLOR_COMPETITION_JERSEY_GRIGIO_CHIARO: 'Grigio Chiaro',
  CFG_COLOR_COMPETITION_JERSEY_GRIGIO_423C: 'Grigio 423 C',
  CFG_COLOR_COMPETITION_JERSEY_GRIGIO_SCURO: 'Grigio Scuro',
  CFG_COLOR_COMPETITION_JERSEY_NERO: 'Nero',
  CFG_COLOR_COMPETITION_JERSEY_ROSSO: 'Rosso',
  CFG_COLOR_COMPETITION_JERSEY_VERDE_BANDIERA: 'Verde Bandiera',
  CFG_COLOR_COMPETITION_JERSEY_BIANCO: 'Bianco',

  CFG_COLOR_COMPETITION_2022_PRIMARIO_AZZURRO_SPARCO: 'Azzurro Sparco',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_ARANCIONE: 'Arancione',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_BIANCO: 'Bianco',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_BLU_MARINE: 'Blu Marine',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_CELESTE: 'Celeste',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_GRIGIO_CHIARO: 'Grigio Chiaro',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_GIALLO_SOLE: 'Giallo Sole',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_GRIGIO_SCURO: 'Grigio Scuro',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_NERO: 'Nero',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_VIOLA: 'Viola',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_ROSSO: 'Rosso',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_VERDE: 'Verde',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_VERDE_ACQUA: 'Verde Acqua',
  CFG_COLOR_COMPETITION_2022_PRIMARIO_VERDE_PISTACCHIO: 'Verde Pistacchio',
  CFG_COLOR_COMPETITION_2022_SOVRAPPOSIZIONE_GIALLO_FLUO: 'Giallo Fluo',
  CFG_COLOR_COMPETITION_2022_SOVRAPPOSIZIONE_GRIGIO_MEDIO: 'Grigio Medio',
  CFG_COLOR_COMPETITION_2022_SOVRAPPOSIZIONE_ROSA: 'Rosa',
  CFG_COLOR_COMPETITION_2022_SOVRAPPOSIZIONE_VERDE_FLUO: 'Verde Fluo',
  CFG_COLOR_COMPETITION_2022_SOVRAPPOSIZIONE_BIANCO: 'Bianco',
  CFG_COLOR_COMPETITION_2022_SOVRAPPOSIZIONE_REFLEX_BLUE: 'Blu Reflex',
  CFG_COLOR_COMPETITION_2022_SOVRAPPOSIZIONE_NERO: 'Nero',
  CFG_COLOR_COMPETITION_2022_SOVRAPPOSIZIONE_ROSSO: 'Rosso',
  CFG_COLOR_COMPETITION_2022_JERSEY_AZZURRO: 'Azzurro',
  CFG_COLOR_COMPETITION_2022_JERSEY_GRIGIO_423C: 'Grigio 423 C',
  CFG_COLOR_COMPETITION_2022_JERSEY_GRIGIO_SCURO: 'Grigio Scuro',
  CFG_COLOR_COMPETITION_2022_POLSI_NERO: 'Nero',

  CFG_COLOR_VICTORY_PRIMARIO_BLU_REFLEX: 'Blu Reflex',
  CFG_COLOR_VICTORY_PRIMARIO_BIANCO_OTTICO: 'Bianco Ottico',
  CFG_COLOR_VICTORY_PRIMARIO_NERO: 'Nero',
  CFG_COLOR_VICTORY_PRIMARIO_GRIGIO_431C: 'Grigio 431 C',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_BLU_MARINE: 'Blu Marine',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_GIALLO_LIMONE: 'Giallo Limone',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_ROSSO: 'Rosso',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_ARANCIO_FLUO: 'Arancio Fluo',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_CELESTE: 'Celeste',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_FUCSIA: 'Fucsia',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_GIALLO: 'Giallo',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_GRIGIO_CHIARO: 'Grigio Chiaro',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_GRIGIO_423C: 'Grigio 423 C',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_GRIGIO_SCURO: 'Grigio Scuro',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_VERDE_METAL: 'Verde Metal',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_VERDE_BANDIERA: 'Verde Bandiera',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_VERDE_LOTUS: 'Verde Lotus',
  CFG_COLOR_VICTORY_SOVRAPPOSIZIONE_VENICE_BORDEAUX: 'Venice Bordeaux',
  CFG_COLOR_VICTORY_JERSEY_BLU_REFLEX: 'Blu Reflex',
  CFG_COLOR_VICTORY_JERSEY_ARANCIO: 'Arancio',
  CFG_COLOR_VICTORY_JERSEY_AZZURRO: 'Azzurro',
  CFG_COLOR_VICTORY_JERSEY_BLU_MARINE: 'Blu Marine',
  CFG_COLOR_VICTORY_JERSEY_GIALLO: 'Giallo',
  CFG_COLOR_VICTORY_JERSEY_GRIGIO_CHIARO: 'Grigio Chiaro',
  CFG_COLOR_VICTORY_JERSEY_GRIGIO_423C: 'Grigio 423 C',
  CFG_COLOR_VICTORY_JERSEY_GRIGIO_SCURO: 'Grigio Scuro',
  CFG_COLOR_VICTORY_JERSEY_NERO: 'Nero',
  CFG_COLOR_VICTORY_JERSEY_ROSSO: 'Rosso',
  CFG_COLOR_VICTORY_JERSEY_VERDE_BANDIERA: 'Verde Bandiera',
  CFG_COLOR_VICTORY_JERSEY_BIANCO: 'Bianco',

  CFG_COLOR_SPRINT2020_PRIMARIO_BLU_ELETTRICO: 'Blu Elettrico',
  CFG_COLOR_SPRINT2020_PRIMARIO_BLU_MARINE: 'Blu Marine',
  CFG_COLOR_SPRINT2020_PRIMARIO_BIANCO: 'Bianco',
  CFG_COLOR_SPRINT2020_PRIMARIO_GIALLO_FLUO: 'Giallo Fluo',
  CFG_COLOR_SPRINT2020_PRIMARIO_GRIGIO_423C: 'Grigio 423 C',
  CFG_COLOR_SPRINT2020_PRIMARIO_NERO: 'Nero',
  CFG_COLOR_SPRINT2020_PRIMARIO_PINK: 'Pink',
  CFG_COLOR_SPRINT2020_PRIMARIO_ROSSO_CITROEN: 'Rosso Citroen',
  CFG_COLOR_SPRINT2020_PRIMARIO_VERDE_FLUO: 'Verde Fluo',
  CFG_COLOR_SPRINT2020_SOVRAPPOSIZIONE_ARANCIO_FLUO: 'Arancio Fluo',
  CFG_COLOR_SPRINT2020_SOVRAPPOSIZIONE_CELESTE: 'Celeste',
  CFG_COLOR_SPRINT2020_SOVRAPPOSIZIONE_FUCSIA: 'Fucsia',
  CFG_COLOR_SPRINT2020_SOVRAPPOSIZIONE_GIALLO: 'Giallo',
  CFG_COLOR_SPRINT2020_SOVRAPPOSIZIONE_ROSSO_VODAFONE: 'Rosso Vodafone',
  CFG_COLOR_SPRINT2020_SOVRAPPOSIZIONE_GRIGIO_CHIARO: 'Grigio Chiaro',
  CFG_COLOR_SPRINT2020_SOVRAPPOSIZIONE_GRIGIO_431C: 'Grigio 431 C',
  CFG_COLOR_SPRINT2020_SOVRAPPOSIZIONE_GRIGIO_SCURO: 'Grigio Scuro',
  CFG_COLOR_SPRINT2020_SOVRAPPOSIZIONE_VERDE_BANDIERA: 'Verde Bandiera',
  CFG_COLOR_SPRINT2020_SOVRAPPOSIZIONE_VERDE_LOTUS: 'Verde Lotus',
  CFG_COLOR_SPRINT2020_SOVRAPPOSIZIONE_VENICE_BORDEAUX: 'Venice Bordeaux',
  CFG_COLOR_SPRINT2020_JERSEY_ARANCIO: 'Arancio',
  CFG_COLOR_SPRINT2020_JERSEY_AZZURRO: 'Azzurro Sparco',
  CFG_COLOR_SPRINT2020_JERSEY_BLU_REFLEX: 'Blu Reflex',
  CFG_COLOR_SPRINT2020_JERSEY_BLU_MARINE: 'Blu Marine',
  CFG_COLOR_SPRINT2020_JERSEY_GIALLO: 'Giallo',
  CFG_COLOR_SPRINT2020_JERSEY_GRIGIO_CHIARO: 'Grigio Chiaro',
  CFG_COLOR_SPRINT2020_JERSEY_GRIGIO_423C: 'Grigio 423 C',
  CFG_COLOR_SPRINT2020_JERSEY_GRIGIO_SCURO: 'Grigio Scuro',
  CFG_COLOR_SPRINT2020_JERSEY_NERO: 'Nero',
  CFG_COLOR_SPRINT2020_JERSEY_ROSSO: 'Rosso',
  CFG_COLOR_SPRINT2020_JERSEY_VERDE_BANDIERA: 'Verde Bandiera',
  CFG_COLOR_SPRINT2020_JERSEY_BIANCO: 'Bianco',

  CFG_COLOR_SPRINT2022_SOVRAPPOSIZIONE_ARANCIO_166C: 'Arancio 166 C',
  CFG_COLOR_SPRINT2022_SOVRAPPOSIZIONE_ARANCIO_FLAME_ORANGE: 'Arancio Flame Orange',
  CFG_COLOR_SPRINT2022_SOVRAPPOSIZIONE_BLU_CHIARO: 'Blu Chiaro',
  CFG_COLOR_SPRINT2022_SOVRAPPOSIZIONE_CELESTE: 'Celeste',
  CFG_COLOR_SPRINT2022_SOVRAPPOSIZIONE_GIALLO: 'Giallo',
  CFG_COLOR_SPRINT2022_SOVRAPPOSIZIONE_GRIGIO_CHIARO: 'Grigio Chiaro',
  CFG_COLOR_SPRINT2022_SOVRAPPOSIZIONE_GRIGIO_ANTRACITE: 'Grigio Antracite',
  CFG_COLOR_SPRINT2022_SOVRAPPOSIZIONE_GRIGIO_SCURO: 'Grigio Scuro',
  CFG_COLOR_SPRINT2022_SOVRAPPOSIZIONE_ROSSO_SCURO: 'Rosso Scuro',
  CFG_COLOR_SPRINT2022_SOVRAPPOSIZIONE_VERDE_AMR: 'Verde AMR',

  CFG_COLOR_XLIGHT_PRIMARIO_BIANCO_OTTICO: 'Bianco Ottico',
  CFG_COLOR_XLIGHT_PRIMARIO_ARANCIO_166C: 'Arancio 166 C',
  CFG_COLOR_XLIGHT_PRIMARIO_ARANCIO_FLAME_ORANGE: 'Arancio Flame Orange',
  CFG_COLOR_XLIGHT_PRIMARIO_AZZURRO_SPARCO: 'Azzurro Sparco',
  CFG_COLOR_XLIGHT_PRIMARIO_BLU_CHIARO: 'Blu Chiaro',
  CFG_COLOR_XLIGHT_PRIMARIO_BLU_MARINE: 'Blu Marine',
  CFG_COLOR_XLIGHT_PRIMARIO_CELESTE: 'Celeste',
  CFG_COLOR_XLIGHT_PRIMARIO_GIALLO: 'Giallo',
  CFG_COLOR_XLIGHT_PRIMARIO_GRIGIO_ANTRACITE: 'Grigio Antracite',
  CFG_COLOR_XLIGHT_PRIMARIO_GRIGIO_423C: 'Grigio 423 C',
  CFG_COLOR_XLIGHT_PRIMARIO_GRIGIO_7545C: 'Grigio 7525 C',
  CFG_COLOR_XLIGHT_PRIMARIO_GRIGIO_CHIARO: 'Grigio Chiaro',
  CFG_COLOR_XLIGHT_PRIMARIO_GRIGIO_SCURO: 'Grigio Scuro',
  CFG_COLOR_XLIGHT_PRIMARIO_ROSSO: 'Rosso',
  CFG_COLOR_XLIGHT_PRIMARIO_VERDE_LOTUS: 'Verde Lotus',
  CFG_COLOR_XLIGHT_PRIMARIO_VERDE_AMR: 'Verde AMR',
  CFG_COLOR_XLIGHT_PRIMARIO_NERO: 'Nero',
  CFG_COLOR_XLIGHT_SOVRAPPOSIZIONE_ARANCIO_FLUO: 'Arancio Fluo',
  CFG_COLOR_XLIGHT_SOVRAPPOSIZIONE_FUCSIA: 'Fucsia',
  CFG_COLOR_XLIGHT_SOVRAPPOSIZIONE_GIALLO_CEDRO: 'Giallo Cedro',
  CFG_COLOR_XLIGHT_SOVRAPPOSIZIONE_ROSSO_VODAFONE: 'Rosso Vodafone',
  CFG_COLOR_XLIGHT_SOVRAPPOSIZIONE_VERDE_METAL: 'Verde Metal',
  CFG_COLOR_XLIGHT_SOVRAPPOSIZIONE_VERDE_BANDIERA: 'Verde Bandiera',
  CFG_COLOR_XLIGHT_SOVRAPPOSIZIONE_VENICE_BORDEAUX: 'Venice Bordeaux',
  CFG_COLOR_XLIGHT_JERSEY_ARANCIO: 'Arancio',
  CFG_COLOR_XLIGHT_JERSEY_AZZURRO: 'Azzurro',
  CFG_COLOR_XLIGHT_JERSEY_BLU_MARINE: 'Blu Marine',
  CFG_COLOR_XLIGHT_JERSEY_GIALLO: 'Giallo',
  CFG_COLOR_XLIGHT_JERSEY_GRIGIO_CHIARO: 'Grigio Chiaro',
  CFG_COLOR_XLIGHT_JERSEY_GRIGIO_423C: 'Grigio 423 C',
  CFG_COLOR_XLIGHT_JERSEY_GRIGIO_SCURO: 'Grigio Scuro',
  CFG_COLOR_XLIGHT_JERSEY_NERO: 'Nero',
  CFG_COLOR_XLIGHT_JERSEY_CHAMATEX_NERO: 'Nero Chamatex',
  CFG_COLOR_XLIGHT_JERSEY_ROSSO: 'Rosso',
  CFG_COLOR_XLIGHT_JERSEY_VERDE_BANDIERA: 'Verde Bandiera',
  CFG_COLOR_XLIGHT_JERSEY_BIANCO: 'Bianco',
  CFG_COLOR_XLIGHT_JERSEY_CHAMATEX_BIANCO: 'Bianco Chamatex',
  CFG_COLOR_XLIGHT_JERSEY_LULO_BIANCO: 'Bianco Lulo',
  CFG_COLOR_XLIGHT_JERSEY_LULO_NERO: 'Nero Lulo',

  CFG_COLOR_XLIGHT_KART_PRIMARIO_BIANCO_OTTICO: 'Bianco Ottico',
  CFG_COLOR_XLIGHT_KART_PRIMARIO_BLU_ELETTRICO: 'Blu Elettrico',
  CFG_COLOR_XLIGHT_KART_PRIMARIO_GRIGIO_CG1C: 'Grigio CG 1 C',
  CFG_COLOR_XLIGHT_KART_PRIMARIO_GRIGIO_431C: 'Grigio 431 C',
  CFG_COLOR_XLIGHT_KART_PRIMARIO_GRIGIO_7546C: 'Grigio 7546 C',
  CFG_COLOR_XLIGHT_KART_PRIMARIO_ROSSO: 'Rosso',
  CFG_COLOR_XLIGHT_KART_PRIMARIO_NERO: 'Nero',
  CFG_COLOR_XLIGHT_KART_SECONDARIO_ARANCIO_FLUO: 'Arancio Fluo',
  CFG_COLOR_XLIGHT_KART_SECONDARIO_GIALLO_FLUO: 'Giallo Fluo',
  CFG_COLOR_XLIGHT_KART_POLSICAVIGLIE_BIANCO: 'Bianco',
  CFG_COLOR_XLIGHT_KART_POLSICAVIGLIE_AZZURRO_SPARCO: 'Azzurro Sparco',
  CFG_COLOR_XLIGHT_KART_POLSICAVIGLIE_BLU_SCURO: 'Blu Scuro',
  CFG_COLOR_XLIGHT_KART_POLSICAVIGLIE_ROSSO: 'Rosso',
  CFG_COLOR_XLIGHT_KART_POLSICAVIGLIE_VERDE_TONY_KART: 'Verde Tony Kart',
  CFG_COLOR_XLIGHT_KART_POLSICAVIGLIE_LONDON_SMOKE: 'London Smoke',
  CFG_COLOR_XLIGHT_KART_POLSICAVIGLIE_NERO: 'Nero',

  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_ARANCIO_166C: 'Arancio 166 C',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_ARANCIO_FLAME_ORANGE: 'Arancio Flame Orange',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_AZZURRO_SPARCO: 'Azzurro Sparco',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_BLU_CHIARO: 'Blu Chiaro',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_BLU_MARINE: 'Blu Marine',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_BIANCO_OTTICO: 'Bianco Ottico',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_CELESTE: 'Celeste',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_GIALLO: 'Giallo',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_GRIGIO_ANTRACITE: 'Grigio Antracite',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_GRIGIO_423C: 'Grigio 423 C',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_GRIGIO_CHIARO: 'Grigio Chiaro',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_GRIGIO_SCURO: 'Grigio Scuro',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_NERO: 'Nero',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_ROSSO_SCURO: 'Rosso Scuro',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_ROSSO: 'Rosso',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_GIALLO_FLUO: 'Giallo Fluo',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_VERDE_AMR: 'Verde AMR',
  CFG_COLOR_SUPERLEGGERA_2022_PRIMARIO_METAL_GREEN: 'Verde Metal',
  CFG_COLOR_SUPERLEGGERA_2022_JERSEY1_AZZURRO: 'Azzurro',
  CFG_COLOR_SUPERLEGGERA_2022_JERSEY1_GRIGIO_CHIARO: 'Grigio Chiaro',
  CFG_COLOR_SUPERLEGGERA_2022_JERSEY1_GRIGIO_423C: 'Grigio 423 C',
  CFG_COLOR_SUPERLEGGERA_2022_JERSEY1_NERO: 'Nero',
  CFG_COLOR_SUPERLEGGERA_2022_JERSEY1_ROSSO: 'Rosso',
  CFG_COLOR_SUPERLEGGERA_2022_JERSEY1_VERDE_BANDIERA: 'Verde Bandiera',
  CFG_COLOR_SUPERLEGGERA_2022_JERSEY1_BIANCO: 'Bianco',
  CFG_COLOR_SUPERLEGGERA_2022_JERSEY2_BIANCO: 'Bianco',
  CFG_COLOR_SUPERLEGGERA_2022_JERSEY2_NERO: 'Nero',
  CFG_COLOR_SUPERLEGGERA_2022_JERSEY2_AZZURRO: 'Azzurro',

  CFG_COLOR_PRIME_RW10_PRIMARIO_BIANCO: 'Bianco',
  CFG_COLOR_PRIME_RW10_PRIMARIO_NERO: 'Nero',

  MODEL_DESCRIPTION_001091SP:
    'La tuta racing Sprint RS-2.1 è la tuta per tutti gli amanti del mondo dei motori. Evoluta in ogni suo dettaglio e nella qualità dei materiali, spinti ora ai massimi livelli, la tuta da pilota Sprint RS-2.1 è realizzata con inserti in maglina nel giro manica e nella schiena. La composizione della tuta da pilota Sprint RS-1 si basa su materiale meta aramidico ed ha un panino composto da due strati il cui peso medio varia tra i 360 ed i 390 grammi al metro quadro.',
  MODEL_DESCRIPTION_0011279SP:
    'Una tuta di gamma alta, realizzata con un compound sottile e di peso medio leggero, ma in particolare con un nuovo tessuto esterno realizzato in parte di fibre naturali, di grande pregio come la seta. (fibra naturale, piacevole al tatto dal look leggermente lucido). Tessuto utilizzato anche in versione traforata per una migliore traspirazione. La tuta è stata progettata pensando al comfort del pilota durante la guida, con inserti elasticizzati in tessuto Eagle (giro manica completo, fianchi, inserti gamba altezza ginocchio e schiena), maniche preformate. La tuta è arricchita di inserti in contrasto in tela ultraleggera e piatta e nuove fodere personalizzate Sparco, che si intravedono in alcuni dettagli, conferendo uno stile caratteristico Sparco.',
  MODEL_DESCRIPTION_001133SP:
    'La tuta racing Prime SP-16 è stata studiata e progettata con l’obiettivo di garantire al pilota leggerezza, confort e traspirabilità. Ogni più piccolo dettaglio nei materiali, nella costruzione e negli accessori è stato portato al limite per poter garantire un risparmio di peso fino all’ultimo grammo possibile. La composizione della tuta da pilota PRIMA SP-16 è data da un panino 3 strati con peso inferiore ai 240/270 grammi/metro quadro composta da Meta Aramidico/Poliammide-immide.',
  MODEL_DESCRIPTION_001134SP:
    'La nuovissima tuta Competition presenta standard qualitativi e stilistici di ultima generazione. La configurazione include un tessuto esterno morbido e leggero, degli inserti traspiranti su fianchi e sottomanica, collo alleggerito, cuciture ultraleggere, maniche preformate, cintura regolabile e tasche nascoste. Il nuovo design pulito e lineare, senza trapuntature, dona slancio ed enfatizza la figura di chiunque indossi la tuta. Composizione : 95% Meta-Aramide/5% Para-Aramide. Assemblaggio 3 strati con un peso medio complessivo di 300/330 gr/mq',
  MODEL_DESCRIPTION_001128SP:
    'La nuovissima tuta Competition presenta standard qualitativi e stilistici di ultima generazione. La configurazione include un tessuto esterno morbido e leggero, degli inserti traspiranti su fianchi e sottomanica, collo alleggerito, cuciture ultraleggere, maniche preformate, cintura regolabile e tasche nascoste. Il nuovo design pulito e lineare, senza trapuntature, dona slancio ed enfatizza la figura di chiunque indossi la tuta. Composizione : 95% Meta-Aramide/5% Para-Aramide. Assemblaggio 3 strati con un peso medio complessivo di 300/330 gr/mq',
  MODEL_DESCRIPTION_0011277HSP:
    'Realizzata in tessuto Hocotex monostrato, la tuta è stata progettata con l’obiettivo di garantire il massimo comfort al pilota durante la guida, con inserti elasticizzati in morbida maglina doppio frontura (giro manica completo e schiena), maniche preformate.  La tuta è arricchita di inserti in contrasto in tela ultraleggera e piatta e nuove fodere personalizzate Sparco, che si intravedono in alcuni dettagli, conferendo uno stile caratteristico Sparco.<br/><br/>Trapuntatura con filo ultrasottile, riprende il classico stile Sparco; maniglia di estrazione composta dalla tradizionale spallina Sparco; tasche a scomparsa, collo e cintura preformate con il caratteristico tratto identificativo Sparco.',
  MODEL_DESCRIPTION_001092SP:
    'La tuta racing Sprint è la tuta per tutti gli amanti del mondo dei motori. Evoluta in ogni suo dettaglio e nella qualità dei materiali, spinti ora ai massimi livelli, la tuta da pilota Sprint  è realizzata con inserti in maglina nel giro manica e nella schiena. Nuova finitura esterna semilucida, tasche frontali.  La composizione della tuta da pilota Sprint RS-1 si basa su materiale meta aramidico ed ha un panino composto da due strati il cui peso medio varia tra i 360 ed i 390 grammi al metro quadro.<br><br>Omologazione : FIA8856-2000 e SFI 3.2A/5 su richiesta',
  MODEL_DESCRIPTION_001093SP:
    'La tuta racing Sprint è la tuta per tutti gli amanti del mondo dei motori. Evoluta in ogni suo dettaglio e nella qualità dei materiali, spinti ora ai massimi livelli, la tuta da pilota Sprint è realizzata con inserti in maglina nel giro manica e nella schiena. Nuova finitura esterna semilucida, tasche frontali. La composizione della tuta da pilota Sprint RS-1 si basa su materiale meta aramidico ed ha un panino composto da due strati il cui peso medio varia tra i 360 ed i 390 grammi al metro quadro.<br><br>Omologazione : FIA8856-2018',
  MODEL_DESCRIPTION_001137SP:
    'La nuovissima tuta Competition presenta standard qualitativi e stilistici di ultima generazione. La configurazione include un tessuto esterno morbido e leggero, degli inserti traspiranti su fianchi e sottomanica, collo alleggerito, cuciture ultraleggere, maniche preformate, cintura regolabile e tasche nascoste. Il nuovo design pulito e lineare, senza trapuntature, dona slancio ed enfatizza la figura di chiunque indossi la tuta. Composizione : 95% Meta-Aramide/5% Para-Aramide. Assemblaggio 3 strati con un peso medio complessivo di 330/360 gr/mq',
  MODEL_DESCRIPTION_001135HSP:
    'Realizzata in tessuto Hocotex monostrato, la tuta è stata progettata con l’obiettivo di garantire il massimo comfort al pilota durante la guida, con inserti elasticizzati in morbida maglina doppio frontura (giro manica completo e schiena), maniche preformate.  La tuta è arricchita di inserti in contrasto in tela ultraleggera e piatta e nuove fodere personalizzate Sparco, che si intravedono in alcuni dettagli, conferendo uno stile caratteristico Sparco.<br/><br/>Trapuntatura con filo ultrasottile, riprende il classico stile Sparco; maniglia di estrazione composta dalla tradizionale spallina Sparco; tasche a scomparsa, collo e cintura preformate con il caratteristico tratto identificativo Sparco.',
  MODEL_DESCRIPTION_001165SP:
    'Una tuta di gamma alta, realizzata con un compound sottile e di peso medio leggero e con il  nuovo tessuto esterno utilizzato per la nostra tuta top di gamma PRIME. La tuta a 3 strati  è stata progettata pensando al comfort del pilota durante la guida, con inserti antiabrasione su gomiti, inserti laterali traspiranti, inserto interno coscia elasticizzato e traspirante, girello completo elasticizzato, pannello posteriore elasticizzato antiabrasione , tasche nascoste, cuciture extrapiatte, polsini e caviglie seamless, cintura<br><br>Omologazione: FIA 8856-2000',
  MODEL_DESCRIPTION_002339SP:
    "L'iconica tuta kart Sparco X-Light vanta una costruzione a 2 strati che offre i massimi livelli di protezione e, al contempo i più alti standard di leggerezza e traspirabilità.Le braccia sono preformati per aumentare il comfort in posizione di guida e  il girello elasticizzato consentono il massimo movimento del pilota.<ul><li>peso : 370/400 g/m2</li><li>pannello posteriore elasticizzato e anti abrasione</li><li>padding ergonomici in nuovo materiale impact-absorber (su esterno gomiti e interno ginocchia)</li><li>inserto traspirante su interno coscia e sottomanica</li><li>inserti antiabrasione (su esterno gomiti e interno ginocchia)</li><li>collo stondato</li><li>tasche inserite nel fianco</li></ul>La tuta kart Sparco X-Light da sempre rappresenta la scelta vincente per i più famosi piloti professionisti e per i kartisti più esigenti.",
  MODEL_DESCRIPTION_001139SP:
    'La nuovissima tuta Competition Pro rappresenta un’ulteriore evoluzione della apprezzatissima gamma Competition . La configurazione include un tessuto esterno morbido e leggero, degli inserti traspiranti su fianchi e sottomanica, collo alleggerito, cuciture ultraleggere, maniche preformate, cintura regolabile e tasche nascoste. Il nuovo design pulito e lineare, senza trapuntature, dona slancio ed enfatizza la figura di chiunque indossi la tuta. Composizione : 95% Meta-Aramide/5% Para-Aramide. Assemblaggio 3 strati con un peso medio complessivo di 330/360 gr/mq',
  MODEL_DESCRIPTION_001142SP:
    '<ul><li>Zip ultraleggera a doppio scorrimento</li><li>Cuciture extrapiatte</li><li>Polsini e caviglie seamless</li><li>Girello completo elasticizzato</li><li>Pannello posteriore elasticizzato</li><li>Inserti elasticizzati sui gomiti</li><li>Inserti elasticizzati sui fianchi</li><li>Inserto interno coscia elasticizzato</li><li>Tasche inserite nei fianchi</li></ul>Omologazione: FIA 8856-2018',
  MODEL_DESCRIPTION_001144SP:
    '<ul><li>Nuovo inserto elastico interno coscia</li><li>Aggiornata con omologa 8856-2018</li><li>Nuovo manicotto elastico seamless per la massima connessione di utilizzo tra tuta e guanti</li></ul>',
  MODEL_DESCRIPTION_001146SP:
    '<ul><li>Zip ultraleggera a doppio scorrimento</li><li>Colletto ultrasottile</li><li>Girello completo elasticizzato</li><li>Nuovo inserto elasticizzato interno coscia</li><li>Nuovo pannello elasticizzato posteriore</li><li>Cuciture extrapiatte</li><li>Polsini e caviglie seamless</li><li>FIA 8856-2018</li></ul>',
  MODEL_DESCRIPTION_001784P:
    '<ul><li>Struttura totalmente seamless</li><li>Cuciture extrapiatte</li><li>Costruzione superleggera</li><li>Trattamento X-COOL rinfrescante ed antiodore</li><li>Decorazioni per mezzo stampa transfer</li><li>Materiale: 96% meta-arame, 4% elastane</li><li>FIA 8856-2018</li></ul>',
  MODEL_DESCRIPTION_001789MSP:
    '<ul><li>Struttura totalmente seamless</li><li>Cuciture extrapiatte</li><li>Costruzione superleggera</li><li>Trattamento X-COOL rinfrescante ed antiodore</li><li>Decorazioni per mezzo stampa transfer</li><li>Materiale: 48% meta-arame, 48% modal viscosa, 4% elastane</li><li>FIA 8856-2018</li></ul>'
}
