import jwtDecode from 'jwt-decode'
import Storage from '@/services/Storage'
import axios from 'axios'

export default {
  state: {
    accessToken: Storage.getItem('accessToken') || null,
    isFetching: false,
    error: null
  },
  getters: {
    isAuthFetching: state => state.isFetching,
    authToken: state => state.accessToken,
    authError: state => state.error,
    isAuthenticated: state => !!state.accessToken,
    loggedUser: state =>
      state.accessToken && typeof state.accessToken === 'string' ? jwtDecode(state.accessToken) : null,
    isAdmin: (state, getters) => (getters.loggedUser ? getters.loggedUser.role === 'ADMIN' : false),
    userCompany: (state, getters) => {
      if (getters.loggedUser) {
        return getters.loggedUser.company
      } else if (getters.b2cDealer) {
        return getters.b2cDealer.company
      } else return null
    },
    hasB2cPrice: (state, getters) => (getters.loggedUser ? getters.loggedUser.b2cPrice : false)
  },
  mutations: {
    AUTH_IS_FETCHING(state) {
      state.isFetching = true
      state.error = null
    },
    AUTH_LOGOUT(state) {
      state.accessToken = null
      state.isFetching = false
      state.error = null
    },
    AUTH_LOGIN_SUCCESS(state, accessToken) {
      state.isFetching = false
      state.error = null
      state.accessToken = accessToken
    },
    AUTH_LOGIN_ERROR(state, error) {
      state.isFetching = false
      state.error = error
    },
    AUTH_RESET_PASSWD_SUCCESS(state) {
      state.isFetching = false
      state.error = null
    }
  },
  actions: {
    AUTH_LOGOUT({ commit }) {
      return new Promise(resolve => {
        commit('AUTH_LOGOUT')
        commit('CONFIGURATOR_RESET_CATALOG_UPDATE_WARNING')
        Storage.removeItem('accessToken')
        resolve()
      })
    },
    AUTH_LOGIN({ commit }, params) {
      return new Promise(resolve => {
        commit('AUTH_IS_FETCHING')
        axios
          .post(process.env.VUE_APP_API_BASE_URL + '/auth/login', {
            username: params.username,
            password: params.password
          })
          .then(res => {
            //il token viene inserito nello storage
            Storage.setItem('accessToken', res.data.accessToken)
            //inserisco il token
            commit('AUTH_LOGIN_SUCCESS', Storage.getItem('accessToken'))
            resolve(true)
          })
          .catch(error => {
            commit('AUTH_LOGIN_ERROR', error.response.data.code)
            resolve(false)
          })
      })
    },
    AUTH_RESET_PASSWD({ commit }, params) {
      return new Promise(resolve => {
        commit('AUTH_IS_FETCHING')
        axios
          .post(process.env.VUE_APP_API_BASE_URL + '/auth/reset-password', {
            email: params.email
          })
          .then(() => {
            commit('AUTH_RESET_PASSWD_SUCCESS')
            resolve(true)
          })
          .catch(() => {
            commit('AUTH_RESET_PASSWD_SUCCESS')
            resolve(true)
          })
      })
    }
  }
}
