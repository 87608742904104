import Vue from 'vue'
import Router from 'vue-router'

import store from '@/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    {
      name: 'login',
      path: '/login',
      component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
      meta: {
        authorized: false,
        mainHeader: false
      }
    },
    {
      name: 'resetpasswd',
      path: '/reset-password',
      component: () => import(/* webpackChunkName: "resetpasswd" */ './views/ResetPassword.vue'),
      meta: {
        authorized: false,
        mainHeader: false
      }
    },
    {
      name: 'dashboard',
      path: '/dashboard',
      component: () => import(/* webpackChunkName: "dashboard" */ './views/Dashboard.vue'),
      meta: {
        authorized: true,
        mainHeader: true
      }
    },
    {
      name: 'B2C_dashboard',
      path: '/b2c/dashboard',
      component: () => import(/* webpackChunkName: "dashboard" */ './views/B2CDashboard.vue'),
      meta: {
        authorized: true,
        mainHeader: true,
        roles: ['DEALER']
      }
    },
    {
      name: 'configurator',
      path: '/configurator/:order',
      component: () => import(/* webpackChunkName: "configurator" */ './views/Configurator.vue'),
      meta: {
        authorized: true,
        mainHeader: false
      }
    },
    {
      name: 'customers',
      path: '/customers',
      component: () => import(/* webpackChunkName: "customers" */ './views/Customers.vue'),
      meta: {
        authorized: true,
        mainHeader: true,
        roles: ['ADMIN']
      }
    },
    {
      name: 'parameters',
      path: '/parameters',
      redirect: { name: 'parametersPricing' },
      component: () => import(/* webpackChunkName: "parameters" */ './views/Parameters/Index.vue'),
      meta: {
        authorized: true,
        mainHeader: true,
        roles: ['ADMIN']
      },
      children: [
        {
          name: 'parametersPricing',
          path: 'pricing',
          component: () => import(/* webpackChunkName: "parametersPricing" */ './views/Parameters/Pricing.vue'),
          meta: {
            authorized: true,
            mainHeader: true,
            roles: ['ADMIN']
          }
        },
        {
          name: 'parametersModels',
          path: 'models',
          component: () => import(/* webpackChunkName: "parametersModels" */ './views/Parameters/Models.vue'),
          meta: {
            authorized: true,
            mainHeader: true,
            roles: ['ADMIN']
          }
        },
        {
          name: 'parametersSurcharges',
          path: 'surcharges',
          component: () => import(/* webpackChunkName: "parametersSurcharges" */ './views/Parameters/Surcharges.vue'),
          meta: {
            authorized: true,
            mainHeader: true,
            roles: ['ADMIN']
          }
        },
        {
          name: 'parametersLogos',
          path: 'logos',
          component: () => import(/* webpackChunkName: "parametersLogos" */ './views/Parameters/Logos.vue'),
          meta: {
            authorized: true,
            mainHeader: true,
            roles: ['ADMIN']
          }
        }
      ]
    },
    // B2C
    {
      name: 'B2C_landing',
      path: '/b2c/start/:token',
      component: () => import(/* webpackChunkName: "b2clanding" */ './views/B2CLanding.vue'),
      meta: {
        authorized: false,
        mainHeader: false
      }
    },
    {
      name: 'B2C_configurator',
      path: '/b2c/customize/:token',
      component: () => import(/* webpackChunkName: "b2cconfigurator" */ './views/Configurator.vue'),
      meta: {
        authorized: false,
        mainHeader: false,
        b2c: true
      }
    },
    // SIZE YOU
    {
      name: 'sizeYou',
      path: '/size-you/:type',
      component: () => import(/* webpackChunkName: "sizeyou" */ './views/SizeYou.vue'),
      meta: {
        authorized: false,
        mainHeader: false
      }
    },
    // NOT FOUND PAGE
    {
      name: 'catchAll',
      path: '*',
      component: () => import(/* webpackChunkName: "notfound" */ './views/NotFound.vue'),
      meta: {
        mainHeader: false
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  const role = store.getters.loggedUser ? store.getters.loggedUser.role : null
  const isRoleAllowed = !to.meta.authorized || (to.meta.roles && to.meta.roles.includes(role))

  if (to.name === 'catchAll' || to.name === 'B2C_landing' || to.name === 'sizeYou') {
    next()
  } else {
    if (!store.getters.isAuthenticated && to.meta.authorized) {
      next({ name: 'login' })
    } else if ((store.getters.isAuthenticated && !to.meta.authorized) || isRoleAllowed === false) {
      next({ name: 'dashboard' })
    } else {
      next()
    }
  }
})

export default router
