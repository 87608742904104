export default {
  // General
  PAGE_NOT_FOUND: 'Page Not Found',
  NO_ITEMS_FOUND: 'No Items Found',
  ERROR_GENERIC: 'Something went wrong. Please Reload',
  DELETE_CONFIRM_QUESTION: 'Delete?',
  YES: 'Yes',
  NO: 'No',
  FROM: 'From',
  TO: 'To',
  CREDITS: 'Credits',
  UNIT_COST: 'Unit Cost',
  CM: 'cm',
  PRICE: 'Price',
  EXIT_SURE_QUESTION: 'Do you really want to leave?\nYou may have unsaved changes!',
  // Header
  HEADER_ORDERS_MENU: 'Orders',
  HEADER_CUSTOMERS_MENU: 'Customers',
  HEADER_PARAMETERS_MENU: 'Parameters',
  HEADER_LOGOUT: 'Logout',
  HEADER_DEALER_LINK_TXT: 'This is the link to your store page, share it with your customers:',
  HEADER_B2B_ORDER: 'Orders',
  HEADER_B2C_ORDER: 'Web enquiries',
  // Login Page
  AUTH_ACCESS_DENIED: 'Username or Password invalid',
  AUTH_FORBIDDEN: 'Username or Password invalid',
  LOGIN_USERNAME: 'Username',
  LOGIN_PASSWORD: 'Password',
  LOGIN_SUBMIT: 'LOGIN',
  LOGIN_FORGOT_LINK: 'Reset Password',
  LOGIN_ERROR_USERNAME_REQUIRED: 'Username is required',
  LOGIN_ERROR_PASSWORD_REQUIRED: 'Password is required',
  LOGIN_RESET_TITLE: 'Insert your email address to receive the new password',
  LOGIN_RESET_EMAIL: 'Email Address',
  LOGIN_RESET_SUBMIT: 'Reset',
  LOGIN_RESET_BACK: 'Back to Login',
  LOGIN_RESET_ERROR_EMAIL_REQUIRED: 'Email Address is required',
  LOGIN_RESET_ERROR_EMAIL_INVALID: 'Email Address not valid',
  LOGIN_RESET_SUCCESS: 'An email with the new password has been sent',
  // Dashboard Page
  DASHBOARD_TITLE: 'Order List',
  DASHBOARD_TITLE_B2C: 'Customer Order List',
  DASHBOARD_NEW_ORDER: 'New Order',
  DASHBOARD_ORDER_CODE: 'Code',
  DASHBOARD_CUSTOMER_NAME: 'Customer Name',
  DASHBOARD_ORDER_DATE: 'Date',
  DASHBOARD_ORDER_STATUS: 'Status',
  DASHBOARD_ORDER_QUANTITY: 'N°',
  DASHBOARD_ORDER_REFERENCE: 'Ref',
  DASHBOARD_ORDER_CREATED: 'Created',
  DASHBOARD_ORDER_SENT: 'Sent',
  DASHBOARD_ORDER_STATUS_NEW: 'New',
  DASHBOARD_ORDER_STATUS_IN_PROGRESS: 'Pending',
  DASHBOARD_ORDER_STATUS_SENT: 'Sent to Sparco',
  DASHBOARD_ORDER_STATUS_CLOSED: 'Closed',
  DASHBOARD_FILTER_TITLE_SEARCH: 'Search',
  DASHBOARD_FILTER_TITLE_SETTINGS: 'View Settings',
  DASHBOARD_FILTER_BY_ORDER: 'By Code',
  DASHBOARD_FILTER_BY_CUSTOMER: 'By Customer Name',
  DASHBOARD_FILTER_BY_DATE: 'By Date',
  DASHBOARD_FILTER_BY_REF: 'By REF',
  DASHBOARD_FILTER_BY_CONSUMER_NAME: 'By Name',
  DASHBOARD_FILTER_BY_CONSUMER_EMAIL: 'By Email',
  DASHBOARD_FILTER_BY_CONSUMER_PHONE: 'By Phone',
  DASHBOARD_FILTER_PLACEHOLDER_ORDER: 'Insert oder code here',
  DASHBOARD_FILTER_PLACEHOLDER_CUSTOMER: 'Insert customer name here',
  DASHBOARD_FILTER_PLACEHOLDER_DATE: 'Pick Date Range',
  DASHBOARD_FILTER_PLACEHOLDER_REF: 'Insert order REF here',
  DASHBOARD_FILTER_PLACEHOLDER_CONSUMER_NAME: 'Insert customer name here',
  DASHBOARD_FILTER_PLACEHOLDER_CONSUMER_EMAIL: 'Insert customer email here',
  DASHBOARD_FILTER_PLACEHOLDER_CONSUMER_PHONE: 'Insert customer phone here',
  DASHBOARD_FILTER_SHOW_SENT: 'Show sent orders',
  DASHBOARD_FILTER_SHOW_CLOSED: 'Show closed orders',
  DASHBOARD_FILTER_RECAP_NO_FILTER: 'No Filters',
  DASHBOARD_FILTER_RECAP_PREFIX: 'Filter',
  DASHBOARD_ORDER_CONSUMER_NAME: 'Name',
  DASHBOARD_ORDER_CONSUMER_EMAIL: 'Email',
  DASHBOARD_ORDER_CONSUMER_PHONE: 'Phone',
  DASHBOARD_DOWNLOAD_S3_MODAL: 'Your download is ready. Click to get it.',

  // Actions
  ACTION_SEARCH: 'Search',
  ACTION_DELETE: 'Delete',
  ACTION_SEND: 'Place order request',
  ACTION_VIEW: 'View rendering',
  ACTION_PRICE: 'Calculate Price',
  ACTION_EXPORT: 'Export',
  ACTION_EDIT: 'Edit',
  ACTION_CLONE: 'Clone',
  ACTION_CLOSE: 'Close',
  ACTION_SAVE: 'Save',
  ACTION_ROLLBACK: 'Rollback',
  ACTION_CANCEL: 'Cancel',
  ACTION_UPLOAD: 'Upload',
  ACTION_LINK: 'Link',
  ACTION_CONFIRM: 'Confirm',
  ACTION_CONSUMER_INFO: 'Customer Info',
  ACTION_PREV: 'Prev',
  ACTION_NEXT: 'Next',
  DASHBOARD_ORDER_CLOSE_CONFIRM_QUESTION: 'Do you want to close this order?',
  DASHBOARD_ORDER_SEND_CONFIRM_QUESTION: 'Send order to Sparco?',
  DASHBOARD_ORDER_SEND_CONFIRM_PAYOFF_1:
    'Sending the customization to Sparco does not automatically entail the placing of a purchase order.',
  DASHBOARD_ORDER_SEND_CONFIRM_PAYOFF_2:
    'The order will be registered only after approval by Sparco and the delivery will take place within 4 weeks from the order submission.',
  DASHBOARD_ORDER_ROLLBACK_CONFIRM_QUESTION: 'Send order back to the dealer {dealerName}?',
  DASHBOARD_ORDER_PRICE_TITLE: 'Quotation Order n. {orderId}',
  DASHBOARD_ORDER_CONFIRM_TITLE: 'Confirm Order n. {orderId}',
  DASHBOARD_ORDER_CONFIRM_QUESTION: 'Do you want to confirm the order of this client?',
  DASHBOARD_ORDER_CONFIRM_PAYOFF:
    'After this operation you will find this order inside your B2B order list, where you can review and send it to Sparco.',
  DASHBOARD_ORDER_DELETE_MULTIPLE_TITLE: 'Delete {orderCount} Orders',
  DASHBOARD_ORDER_DELETE_MULTIPLE_QUESTION: 'Do you want to delete following orders?',
  // Customers Page
  CUSTOMERS_TITLE: 'Customer List',
  CUSTOMERS_NEW_USER: 'New Customer',
  CUSTOMERS_CLOSE_PANEL: 'Close Panel',
  CUSTOMERS_CODE: 'Customer Code',
  CUSTOMERS_NAME: 'Customer Name',
  CUSTOMERS_EMAIL: 'Email',
  CUSTOMERS_CREATED: 'Created',
  CUSTOMERS_COMPANY: 'Company',
  CUSTOMERS_DISCOUNT: 'Discount (%)',
  CUSTOMERS_LOGO: 'Logo',
  CUSTOMERS_CODE_LABEL: 'Customer Code',
  CUSTOMERS_CODE_PLACEHOLDER: 'Insert the customer code here',
  CUSTOMERS_NAME_LABEL: 'Customer Name',
  CUSTOMERS_NAME_PLACEHOLDER: 'Insert the customer name here',
  CUSTOMERS_EMAIL_LABEL: 'Email address',
  CUSTOMERS_EMAIL_PLACEHOLDER: 'Insert the email address here',
  CUSTOMERS_DISCOUNT_LABEL: 'Discount Percentage (0-100)',
  CUSTOMERS_DISCOUNT_PLACEHOLDER: 'Insert the discount percentage here',
  CUSTOMERS_PASSWORD_LABEL: 'Password',
  CUSTOMERS_PASSWORD_PLACEHOLDER: 'Insert the password here',
  CUSTOMERS_CONFIRM_PASSWORD_LABEL: 'Repeat password',
  CUSTOMERS_CONFIRM_PASSWORD_PLACEHOLDER: 'Insert the password again here',
  CUSTOMERS_COMPANY_LABEL: 'Company',
  CUSTOMERS_COMPANY_PLACEHOLDER: 'Insert the company here',
  CUSTOMERS_CREATE_BTN: 'Create Customer',
  CUSTOMERS_EDIT_BTN: 'Edit Customer',
  CUSTOMERS_ERROR_CODE_REQUIRED: 'Customer code is required',
  CUSTOMERS_ERROR_NAME_REQUIRED: 'Customer name is required',
  CUSTOMERS_ERROR_EMAIL_REQUIRED: 'Email address is required',
  CUSTOMERS_ERROR_EMAIL_INVALID: 'Email address not valid',
  CUSTOMERS_ERROR_DISCOUNT_REQUIRED: 'Discount percentage is required',
  CUSTOMERS_ERROR_DISCOUNT_INVALID: 'Discount percentage not valid (0 - 100)',
  CUSTOMERS_ERROR_PASSWORD_REQUIRED: 'Password is required',
  CUSTOMERS_ERROR_CONFIRM_PASSWORD_INVALID: "Confirm Password doesn't match",
  CUSTOMERS_ERROR_CODE_UNIQUE: 'A record with this dealer code already exists',
  CUSTOMERS_ERROR_EMAIL_UNIQUE: 'A record with this email address already exists',
  CUSTOMERS_ERROR_NAME_UNIQUE: 'A record with this dealer name address already exists',
  CUSTOMERS_ERROR_COMPANY_REQUIRED: 'Company is required',
  CUSTOMERS_COMPANY_ITA: 'Sparco Italy',
  CUSTOMERS_COMPANY_USA: 'Sparco USA',
  CUSTOMERS_LOGO_LABEL: 'Dealer Logo (GIF, PNG, JPG - Max 500 KB)',
  CUSTOMERS_ERROR_LOGO_SIZE: 'Max file dimension: 500 KB',
  CUSTOMERS_ERROR_LOGO_MIME: 'Accepted files: GIF, PNG, JPG',
  CUSTOMER_LANDING_LINK: 'Landing Page Link',
  CUSTOMER_CONFIGURATOR_LINK: 'Configurator Link',
  CUSTOMERS_FILTER_TITLE_SEARCH: 'Search Customer Name',
  CUSTOMERS_FILTER_PLACEHOLDER_NAME: 'Insert customer name here',
  CUSTOMERS_FILTER_RECAP_NO_FILTER: 'No Filters',
  CUSTOMERS_FILTER_RECAP_PREFIX: 'Filter',
  CUSTOMERS_FILTER_BY_NAME: 'By Customer Name',
  CUSTOMERS_HIDE_DESCRIPTION_PRICE: 'Hide Model Price',

  // Parameters Page
  PARAMETERS_TITLE: 'Parameters Settings',
  PARAMETERS_SURCHARGES_CATEGORY: 'Categeory',
  PARAMETERS_SURCHARGES_ITEM: 'Item',
  PARAMETERS_NAV_PRICING: 'Logos Price',
  PARAMETERS_NAV_MODELS: 'Product Prices',
  PARAMETERS_NAV_SURCHARGES: 'Surcharges',
  PARAMETERS_NAV_LOGOS: 'Logo Library',
  PARAMETERS_PRICING_LOGO_TITLE: 'Logos Surface Price',
  PARAMETERS_PRICING_DIGIT_TITLE: 'Digitalization Price',
  PARAMETERS_PRICING_ADD_RULE: 'Add Rule',
  PARAMETERS_PRICING_SAVE: 'Save Settings',
  PARAMETERS_MODELS_TITLE: 'Products Prices',
  PARAMETERS_MODELS_SKU: 'SKU',
  PARAMETERS_MODELS_PRODUCT_NAME: 'Product Name',
  PARAMETERS_MODELS_PRODUCT_COMPANY: 'Company',
  PARAMETERS_PRICING_CREDIT_COST_TITLE: 'Credit Price',
  PARAMETERS_PRICING_CREDIT_COST_MODE: 'Mode',
  PARAMETERS_PRICING_CREDIT_COST_MODE_PRINT: 'Print',
  PARAMETERS_PRICING_CREDIT_COST_MODE_EMBROIDERY: 'Embroidery',
  PARAMETERS_PRICING_CREDIT_COST_PRICE: 'Price',
  PARAMETERS_PRICING_INCLUDED_CREDIT_TITLE: 'Free Credits',
  PARAMETERS_PRICING_INCLUDED_CREDIT_NUM: 'Number of free credits',
  PARAMETERS_SIZES_STANDARD: 'Standard Regulations',
  PARAMETERS_SIZES_CUSTOM: 'Customized',
  PARAMETERS_NASCAR_CUFFS: 'Boot Cut',
  PARAMETERS_UPLOAD_HINT: 'Upload image: jpg, png, GIF',
  PARAMETERS_LOG_TEXT_HINT: 'Insert label:',
  PARAMETERS_LOG_TABLE_HEAD_LOGO: 'Logo',
  PARAMETERS_LOG_TABLE_HEAD_LABEL: 'Label',
  // Configurator Page
  CFG_HEAD_EXIT: 'Exit',
  CFG_FOOT_SAVE_BTN: 'View Summary/save',
  CFG_FOOT_BACK_CONFIG: 'Back to Customization',

  CFG_START_TITLE: "Let's start",
  CFG_START_INTRO_TXT: 'Please select the <strong>{colorType} color</strong> for your suit',
  CFG_START_INTRO_TXT_PRIMARY: 'primary',
  CFG_START_INTRO_TXT_SECONDARY: 'secondary',
  CFG_START_INTRO_TXT_SKIP: 'Skip this step',

  CFG_MENU_DESIGN: 'Model',
  CFG_MENU_STYLE: 'Style',
  CFG_MENU_COLOR: 'Color',
  CFG_MENU_TEXT: 'Text',
  CFG_MENU_SIZE: 'Size',
  CFG_MENU_LOGO: 'Logo',
  CFG_MENU_SUMMARY: 'Summary',

  CFG_DESIGN_TITLE_MODEL: 'Select Model',
  CFG_DESIGN_TITLE_STYLE: 'Select Style',
  CFG_DESIGN_TITLE_POCKET: 'Pockets',
  CFG_DESIGN_TITLE_NAVIGATOR_POCKET: 'Navigator Pockets',
  CFG_DESIGN_TITLE_BELT: 'Belt',
  CFG_DESIGN_TITLE_NASCAR_CUFFS: 'Boot Cut',
  CFG_DESIGN_PRICE_FROM: 'Price from',

  CFG_AREA1_NAME: 'Area 1',
  CFG_AREA2_NAME: 'Area 2',
  CFG_AREA3_NAME: 'Area 3',
  CFG_AREA4_NAME: 'Area 4',
  CFG_JERSEY1_NAME: 'Stretch Panel',
  CFG_JERSEY2_NAME: 'Jersey 2',
  CFG_POLSICAVIGLIE_NAME: 'Cuffs',
  CFG_POLSI_NAME: 'Wrists',
  CFG_CAVIGLIE_NAME: 'Ankles',

  CFG_COLOR_TITLE_AREA: 'Select Area',
  CFG_COLOR_TITLE_COLOR: 'Select Color',

  CFG_TEXT_TITLE_CUSTOMIZE: 'Name on belt',
  CFG_TEXT_TITLE_COLOR: 'Select Color',
  CFG_TEXT_PLACEHOLDER_NAME: 'Name',
  CFG_TEXT_PLACEHOLDER_FLAG: 'Country',
  CFG_TEXT_PLACEHOLDER_BLOOD: 'Blood Type',
  CFG_TEXT_WARNING_TITLE: 'Attention',
  CFG_TEXT_WARNING_TEXT:
    ' on some models the positioning of the graphic elements may differ slightly from what is displayed due to production constraints.',

  CFG_SIZE_TITLE_TYPE: 'Select size type',
  CFG_SIZE_STANDARD: 'Standard',
  CFG_SIZE_CUSTOMIZED: 'Custom',
  CFG_SIZE_GENDER_MALE: 'Male',
  CFG_SIZE_GENDER_FEMALE: 'Female',
  CFG_SIZE_TITLE_STANDARD: 'Standard size',
  CFG_SIZE_TITLE_REGULATE: 'Regulate measures',
  CFG_SIZE_TITLE_REGULATE_NOTE: 'Regulations will increase the final price',
  CFG_SIZE_TITLE_GET_YOUR: 'Get your measurements',
  CFG_SIZE_TITLE_GET_YOUR_SOON_AVAILABLE: 'Feature will be available soon',
  CFG_SIZE_TITLE_CUSTOMIZED: 'Custom Measurements',

  CFG_SIZE_PESO: 'Weight',
  CFG_SIZE_ALTEZZA: 'Height',
  CFG_SIZE_TORACE: 'A. Thorax Circumference',
  CFG_SIZE_TORACE_DESCRIPTION:
    'Use a tape measure, run under armpit, put in the largest part of the chest in horizontal position, arms at your sides.',
  CFG_SIZE_VITA: 'B. Waist Circumference',
  CFG_SIZE_VITA_DESCRIPTION: 'Run tape measure around waist horizontally, arms at your sides.',
  CFG_SIZE_BACINO: 'C. Pelvis Circumference',
  CFG_SIZE_BACINO_DESCRIPTION:
    'Run tape measure around basin in the largest point, arms at your sides. Put the tape measure 1820 cm under waist point.',
  CFG_SIZE_COSCIA: 'D. Thigh Circumference',
  CFG_SIZE_COSCIA_DESCRIPTION:
    'Run tape measure around the thigh horizontally, try to measure the largest part of the thigh. You should be around 10 cm from the crotch.',
  CFG_SIZE_SPALLE: 'E. Shoulder Width',
  CFG_SIZE_SPALLE_DESCRIPTION:
    'Arms at your sides, run tape measure from bone to bone. It is important that the tape is rigid in order to obtain a proper measure.',
  CFG_SIZE_MANICA: 'F. Sleeve Length',
  CFG_SIZE_MANICA_DESCRIPTION:
    'Start the measurement from shoulder bone, running the tape measure around elbow (arm slightly flexed) until wrist bone.',
  CFG_SIZE_LARGHEZZA_GAMBA: 'G. Frontal chest length',
  CFG_SIZE_GAMBA_DESCRIPTION:
    'Take the measure starting from the notch of the neck to the front of crotch (reference stitch of the underwear). It is important to put the tape measure in the correct position, always keeping in tension in a vertical position.',
  CFG_SIZE_GAMBA_ESTERNO: 'H. External Leg Length',
  CFG_SIZE_GAMBA_ESTERNO_DESCRIPTION: 'Put tape measure laterally, and run the tape measure from waist to ankle bone.',
  CFG_SIZE_GOMITO: 'L. Internal leg length',
  CFG_SIZE_GOMITO_DESCRIPTION: 'Run tape from crotch to internal ankle bone.',
  CFG_SIZE_DIETRO: 'M. Rear Length',
  CFG_SIZE_DIETRO_DESCRIPTION: 'Run the tape measure from lower vertebra of the neck to the waist (see point 2).',
  CFG_SIZE_CAVALLO_DIETRO: 'N. Neck circumference',
  CFG_SIZE_CAVALLO_DIETRO_DESCRIPTION:
    'Run your tape measure around the neck and close the circle. Please indicate in the space below the value in centimeters.',
  CFG_SIZE_BICIPITE: 'O. Biceps Circumference',
  CFG_SIZE_BICIPITE_DESCRIPTION:
    'Run the tape measure around the widest point of the biceps. Close the circle with the tape measure and indicate in the space below the value in centimeters.',
  CFG_SIZE_FONDO_MANICA: 'P. Total crotch length',
  CFG_SIZE_FONDO_MANICA_DESCRIPTION:
    'Take the measuring tape and put it in front of your body. The measurement should be taken starting from the notch of the neck to the lower vertebra of the neck, passing by the horse. The tape should then have a complete round from the front to the back.',
  CFG_SIZE_CIRCONFERENZA_POLPACCIO: 'Q. Calf Circumference',
  CFG_SIZE_CIRCONFERENZA_POLPACCIO_DESCRIPTION: 'Run tape measure around the widest point of the calf.',
  CFG_SIZE_COLLO_ALLA_VITA_FRONTE: 'R. Neck To Waist (front)',
  CFG_SIZE_COLLO_ALLA_VITA_FRONTE_DESCRIPTION: 'Run tape measure from notch of the neck in front to waist strap.',
  CFG_SIZE_CIRCONFERENZA_AVAMBRACCIO: 'S. Forearm Circumference',
  CFG_SIZE_CIRCONFERENZA_AVAMBRACCIO_DESCRIPTION: 'Run the tape measure around the widest point of the forearm.',
  CFG_SIZE_CIRCONFERENZA_POLSO: 'T. Wrist Circumference',
  CFG_SIZE_CIRCONFERENZA_POLSO_DESCRIPTION: 'Run the tape measure around the wrist bone.',
  CFG_SIZE_LARGHEZZA_TORACE: 'U. Chest Width',
  CFG_SIZE_LARGHEZZA_TORACE_DESCRIPTION: 'Arms at your sides, run tape measure from armpit to armpit.',
  CFG_SIZE_LARGHEZZA_SCHIENALE: 'V. Back Width',
  CFG_SIZE_LARGHEZZA_SCHIENALE_DESCRIPTION: 'Arms at your sides, run tape measure from armpit to armpit.',
  CFG_SIZE_CIRCONFERENZA_CAVIGLIA: 'Z. Ankle Circumference',
  CFG_SIZE_CIRCONFERENZA_CAVIGLIA_DESCRIPTION: 'Run the tape measure around the ankle bone.',
  CFG_SIZE_STD_SUFFIX_XS: '',
  CFG_SIZE_STD_SUFFIX_S: '',
  CFG_SIZE_STD_SUFFIX_M: '',
  CFG_SIZE_STD_SUFFIX_L: '',
  CFG_SIZE_STD_SUFFIX_XL: '',
  CFG_SIZE_STD_SUFFIX_XXXL: '',
  'CFG_SIZE_STD_SUFFIX_S/M': '',
  'CFG_SIZE_STD_SUFFIX_L/XL': '',
  CFG_SIZE_STD_SUFFIX_XXL: '',
  CFG_SIZE_STD_SUFFIX_48: ' - Small',
  CFG_SIZE_STD_SUFFIX_50: '',
  CFG_SIZE_STD_SUFFIX_52: ' - Medium',
  CFG_SIZE_STD_SUFFIX_54: '',
  CFG_SIZE_STD_SUFFIX_56: ' - Large',
  CFG_SIZE_STD_SUFFIX_58: '',
  CFG_SIZE_STD_SUFFIX_60: ' - X-Large',
  CFG_SIZE_STD_SUFFIX_62: '',
  CFG_SIZE_STD_SUFFIX_64: ' - XX-Large',
  CFG_SIZE_STD_SUFFIX_66: '',
  CFG_SIZE_STD_SUFFIX_120: ' - Kid',
  CFG_SIZE_STD_SUFFIX_130: ' - Kid',
  CFG_SIZE_STD_SUFFIX_140: ' - Kid',
  CFG_SIZE_STD_SUFFIX_150: ' - Kid',

  CFG_LOGO_PETTO: 'Front Logos',
  CFG_LOGO_GAMBE: 'Legs Logos',
  CFG_LOGO_MANICASX: 'Left Sleeve Logos',
  CFG_LOGO_MANICADX: 'Right Sleeve Logos',
  CFG_LOGO_SCHIENA: 'Back Logos',
  CFG_LOGO_TITLE_SELECT: 'Select area',
  CFG_LOGO_TITLE_UPLOAD: 'Upload Logos',
  CFG_LOGO_TITLE_MODE: 'Mode',
  CFG_LOGO_TITLE_MODE_PRINT: 'Print',
  CFG_LOGO_TITLE_MODE_EMBROIDERY: 'Embroidery',
  CFG_LOGO_UPLOAD_HINT: 'Upload<br>jpg, png, GIF',
  CFG_LOGO_LIBRARY: 'Library',
  CFG_LOGO_LIBRARY_NO_ELEMS: 'There is no logo in the library',
  CFG_LOGO_LIBRARY_NO_ELEM_BY_FILTER: 'No results found',
  CFG_LOGO_WARNING_HINT: 'Read<br>the instructions',
  CFG_LOGO_WARNING_TITLE: 'Warning',
  CFG_LOGO_INSTRUCTION:
    '<b>To process your order it is mandatory to send the uploaded logos in a vector format (AI or EPS) after submitting the order.</b><br><br>To achieve the best end result, SPARCO may decide to make a logos adjustment such as placement and sizing, even if these will not significantly affect the style of your creation. It is advisable not to insert logos on an area with multiple color; we can do the job, but the result may be imperfect.',
  CFG_LOGO_INSTRUCTION_UNDERWEAR:
    '<b>To process your order it is mandatory to send the uploaded logos in a vector format (AI or EPS) after submitting the order.</b><br><br>To achieve the best end result, SPARCO may decide to make a logos adjustment such as placement and sizing, even if these will not significantly affect the style of your creation.',
  CFG_LOGO_NUM_LOGOS: 'Inserted Logos',
  CFG_LOGO_CREDIT: 'Consumed Credits',
  CFG_LOGO_PRICE: 'Price',
  CFG_ERROR_FILENOTSUPPORTED: 'File not supported',
  CFG_ERROR_FILESIZE: 'The image exceeds maximum size',
  CFG_ERROR_GENERIC: 'Upload error',

  CFG_SUMMARY_CONFIGURATION: 'Base Price',
  CFG_SUMMARY_MEASURES: 'Measures Surcharge',
  CFG_SUMMARY_LOGOS: 'Logos',
  CFG_SUMMARY_DIGITALIZATION: 'Digitalization',
  CFG_SUMMARY_PRICE: 'Total',
  CFG_SUMMARY_TABLE_MODEL: 'Model',
  CFG_SUMMARY_TABLE_STYLE: 'Style',
  CFG_SUMMARY_TABLE_POCKET: 'Pockets',
  CFG_SUMMARY_TABLE_NAVIGATOR_POCKET: 'Navigator Pockets',
  CFG_SUMMARY_TABLE_BELT: 'Belt',
  CFG_SUMMARY_TABLE_NASCAR_CUFFS: 'Boot Cut',
  CFG_SUMMARY_TABLE_COLORS: 'Colors',
  CFG_SUMMARY_TABLE_TEXT: 'Text',
  CFG_SUMMARY_TABLE_TEXT_COUNTRY: 'Country',
  CFG_SUMMARY_TABLE_TEXT_BLOOD: 'Blood Type',
  CFG_SUMMARY_TABLE_TEXT_NAME: 'Name',
  CFG_SUMMARY_TABLE_TEXT_COLOR: 'Text Color',
  CFG_SUMMARY_TABLE_SIZE: 'Size',
  CFG_SUMMARY_TABLE_SIZE_STD: 'Standard',
  CFG_SUMMARY_TABLE_SIZE_CUSTOM: 'Customized',
  CFG_SUMMARY_TABLE_SIZE_REGULATIONS: 'Regulations',
  CFG_SUMMARY_TABLE_SIZE_MEASURES: 'Measures',
  CFG_SUMMARY_TABLE_LOGOS: 'Logos',
  CFG_SUMMARY_TABLE_LOGOS_NUM: 'Inserted Logos',
  CFG_SUMMARY_TABLE_LOGOS_CREDIT: 'Consumed Credits',
  CFG_SUMMARY_TABLE_LOGOS_MODE: 'Mode',
  CFG_SUMMARY_TABLE_LOGOS_MODE_PRINT: 'Print',
  CFG_SUMMARY_TABLE_LOGOS_MODE_EMBROIDERY: 'Embroidery',
  CFG_SUMMARY_QUANTITY: 'Quantity',
  CFG_SUMMARY_NOTES: 'Notes',
  CFG_SUMMARY_REF: 'REF',
  CFG_SUMMARY_CONSUMER_NAME: 'Full Name',
  CFG_SUMMARY_CONSUMER_EMAIL: 'Email',
  CFG_SUMMARY_CONSUMER_PHONE: 'Phone',
  CFG_SUMMARY_SAVE: 'Save',
  CFG_SUMMARY_SAVE_EXIT: 'Save and Exit',
  CFG_SUMMARY_SAVE_ERROR: 'The operation could not be completed.',
  CFG_SUMMARY_VALIDATION_ERROR_SIZE: 'Complete all measures',
  CFG_SUMMARY_VALIDATION_ERROR_QTY: 'Invalid quantity.',
  CFG_SUMMARY_VALIDATION_ERROR_CONSUMER_NAME: 'Full Name is required.',
  CFG_SUMMARY_VALIDATION_ERROR_CONSUMER_EMAIL: 'Invalid Email address.',
  CFG_SUMMARY_VALIDATION_ERROR_CONSUMER_PHONE: 'Invalid Phone number',
  CFG_SUMMARY_B2C_SEND: 'Send Order<br class="d-xl-none" /> Request',
  CFG_SUMMARY_B2C_SEND_PROCESS_TXT_1: 'We are processing your order.',
  CFG_SUMMARY_B2C_SEND_PROCESS_TXT_2: 'You will soon receive a confirmation email.',
  CFG_SUMMARY_B2C_SEND_PROCESS_TXT_3: 'Do not close your browser during the operations.',
  CFG_SUMMARY_B2C_REQUIRED_FIELDS: 'Required Fields.',

  CFG_B2C_THANK_YOU_TITLE: 'Thank You',
  CFG_B2C_THANK_YOU_TEXT: 'Your order has been correctly sent.',

  PDF_MODEL: 'Model',
  PDF_STYLE: 'Style',
  PDF_SKU: 'SKU',
  PDF_ORDER_CODE: 'Order Code',
  PDF_ORDER_DATA_TITLE: 'Order Data',
  PDF_ORDER_DATA_QUANTITY: 'Quantity',
  PDF_ORDER_DATA_NOTES: 'Notes',
  PDF_ORDER_DATA_REF: 'REF',
  PDF_AREAS_TITLE: 'Areas',
  PDF_AREA_NAME: 'Area Name',
  PDF_AREA_MAT: 'Material Name',
  PDF_AREA_CODE_MAT: 'Material Code',
  PDF_TEXT_TITLE: 'Text and Country',
  PDF_LOGOS: 'Logos',
  PDF_LOGOS_MODE: 'Mode',
  PDF_LOGOS_MODE_PRINT: 'Print',
  PDF_LOGOS_MODE_EMBROIDERY: 'Embroidery',
  PDF_LOGOS_NUM: 'Inserted Logos',
  PDF_LOGOS_CREDIT: 'Consumed Credits',
  PDF_ACCESSORIES_TITLE: 'Accessories',
  PDF_POCKET_TITLE: 'Pockets',
  PDF_NAVIGATOR_POCKET_TITLE: 'Navigator Pockets',
  PDF_BELT_TITLE: 'Belt',
  PDF_NASCAR_CUFFS_TITLE: 'Boot Cut',
  PDF_TEXT_COUNTRY: 'Country',
  PDF_TEXT_NAME: 'Name',
  PDF_TEXT_BLOOD: 'Blood Type',
  PDF_TEXT_FONT: 'Font',
  PDF_TEXT_COLOR: 'Text Color',
  PDF_SIZE_TITLE: 'Size',
  PDF_SIZE_STD: 'Standard',
  PDF_SIZE_CUSTOM: 'Customized',
  PDF_SIZE_PART: 'Part',
  PDF_SIZE_DIMENSION_STD: 'Regulations',
  PDF_SIZE_DIMENSION_CUSTOM: 'Measures',

  SIZEYOU_USERNAME: 'Customer Username',
  SIZEYOU_ERROR_TITLE: 'Error',
  SIZEYOU_ERROR_DESC: 'Please close and retry.',
  CFG_SUMMARY_SIZEYOU_USER: 'SizeYou User',

  // NEW LABELS - post invio per traduzione francese
  INFO_TOOLTIP: 'Learn More',
  SIZEYOU_INSTRUCTIONS_1: 'Get SizeYou Professional App',
  SIZEYOU_INSTRUCTIONS_2: 'Take two pictures following the tutorial',
  SIZEYOU_INSTRUCTIONS_3: 'Send your measures to Sparco to complete your order',
  CFG_SUMMARY_HOMOLOGATION: 'Homologation',
  PDF_HOMOLOGATION: 'Homologation',
  PARAMETERS_PRODUCT_DISABLED: 'Product disabled',
  OUT_OF_CATALOG: 'Out of Catalog',
  CFG_AREA5_NAME: 'Area 5',
  CFG_JERSEY3_NAME: 'Jersey 3',
  CATALOG_CHANGE_WARNING:
    'ATTENTION: following the go-live of the new Catalog 2022, orders previously created in draft and not yet sent to Sparco will no longer be manageable. You will need to create other orders based on the new catalog.'
}
