import axios from 'axios'

export default {
  state: {
    isFetching: false,
    error: null,
    models: [],
    pricing: {}
  },
  getters: {
    isSettingsFetching: state => state.isFetching,
    settingsError: state => state.error,
    settingsPricing: state => state.pricing,
    settingsPricingLogoSurface: state => state.pricing.logoSurface || [],
    settingsPricingDigitalization: state => state.pricing.digitalization || [],
    settingsPricingCreditCost: state => state.pricing.creditCost || { PRINT: 0, EMBROIDERY: 0 },
    settingsPricingSizes: state => state.pricing.customizedSizePrice || { standard: 0, customized: 0 },
    settingsPricingNascarCuffs: state => state.pricing.nascarCuffs || { ITA: 0, USA: 0 },
    settingsModels: state => state.models
  },
  mutations: {
    SETTINGS_IS_FETCHING(state) {
      state.isFetching = true
      state.error = null
    },
    SETTINGS_READ_SUCCESS(state, payload) {
      state.isFetching = false
      state.pricing = payload.pricing || {}
    },
    SETTINGS_ERROR(state, payload) {
      state.isFetching = false
      state.error = payload
      state.pricing = {}
      state.models = []
    },
    SETTINGS_SET_LOGO_SURFACE(state, payload) {
      state.pricing.logoSurface = payload
    },
    SETTINGS_SET_DIGITALIZATION(state, payload) {
      state.pricing.digitalization = payload
    },
    SETTINGS_GET_MODELS_SUCCESS(state, payload) {
      state.isFetching = false
      state.models = payload
    },
    SETTINGS_UPDATE_MODEL_SUCCESS(state) {
      state.isFetching = false
    },
    AUTH_LOGOUT(state) {
      state.isFetching = false
      state.error = null
      state.models = []
      state.pricing = {}
    }
  },
  actions: {
    SETTINGS_READ({ state, commit }) {
      if (!state.pricing || Object.keys(state.pricing).length === 0) {
        commit('SETTINGS_IS_FETCHING')
        axios
          .get(process.env.VUE_APP_API_BASE_URL + '/public/settings')
          .then(res => {
            commit('SETTINGS_READ_SUCCESS', res.data)
          })
          .catch(error => {
            commit('SETTINGS_ERROR', error.response)
          })
      }
    },
    SETTINGS_UPDATE({ commit }, data) {
      commit('SETTINGS_IS_FETCHING')
      axios
        .put(process.env.VUE_APP_API_BASE_URL + '/settings', data)
        .then(() => {
          commit('SETTINGS_READ_SUCCESS', data)
        })
        .catch(error => {
          commit('SETTINGS_ERROR', error.response)
        })
    },
    SETTINGS_SET_LOGO_SURFACE({ commit }, items) {
      commit('SETTINGS_SET_LOGO_SURFACE', items)
    },
    SETTINGS_SET_DIGITALIZATION({ commit }, items) {
      commit('SETTINGS_SET_DIGITALIZATION', items)
    },
    SETTINGS_GET_MODELS({ commit }) {
      commit('SETTINGS_IS_FETCHING')
      axios
        .get(process.env.VUE_APP_API_BASE_URL + '/catalog/models')
        .then(res => {
          commit('SETTINGS_GET_MODELS_SUCCESS', res.data)
        })
        .catch(error => {
          commit('SETTINGS_ERROR', error.response)
        })
    },
    SETTINGS_UPDATE_MODEL({ commit }, payload) {
      commit('SETTINGS_IS_FETCHING')
      axios
        .put(process.env.VUE_APP_API_BASE_URL + '/catalog/models/' + payload.id, {
          priceITA: payload.priceITA,
          priceUSA: payload.priceUSA
        })
        .then(() => {
          commit('SETTINGS_UPDATE_MODEL_SUCCESS')
        })
        .catch(error => {
          commit('SETTINGS_ERROR', error.response)
        })
    }
  }
}
