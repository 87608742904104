import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-material-design-icons/styles.css'
import 'v-calendar/lib/v-calendar.min.css'

import Vue from 'vue'
import axios from 'axios'
import BootstrapVue from 'bootstrap-vue'
import VueI18n from 'vue-i18n'
import moment from 'moment'
import ToggleButton from 'vue-js-toggle-button'
import VCalendar from 'v-calendar'

import App from './App.vue'
import router from './router'
import store from './store'

import en from '@/assets/translation/en'
import it from '@/assets/translation/it'
import enProducts from '@/assets/translation/products/en'
import itProducts from '@/assets/translation/products/it'

// Axios Init
axios.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    //caso in cui sono dentro ma il token è scaduto
    if (403 === error.response.status && store.getters.isAuthenticated) {
      store.dispatch('AUTH_LOGOUT').then(() => {
        router.push({ name: 'login' })
      })
      return
    }
    return Promise.reject(error)
  }
)

axios.interceptors.request.use(
  function(config) {
    //se sono autenticato...
    if (store.getters.isAuthenticated) {
      //innesto il token
      config.headers.Authorization = 'Bearer ' + store.getters.authToken
    }
    return config
  },
  function(err) {
    return Promise.reject(err)
  }
)

Vue.config.productionTip = false

//i18n configuration
Vue.use(VueI18n)
const availableLanguages = ['en', 'it']
const detectedLanguage = navigator && navigator.language ? navigator.language.split('-').shift() : null
const i18n = new VueI18n({
  locale: availableLanguages.includes(detectedLanguage) ? detectedLanguage : 'en',
  fallbackLocale: 'en',
  messages: {
    it: { ...it, ...itProducts },
    en: { ...en, ...enProducts }
  }
})

// UI Plugins
Vue.use(BootstrapVue)
Vue.use(ToggleButton)
Vue.use(VCalendar, { locale: detectedLanguage })

// Filters
Vue.filter('date', function(value) {
  if (!value) return '-'
  const formatString = store.getters.userCompany === 'USA' ? 'MM/DD/YYYY h:mm:ss A' : 'DD/MM/YYYY HH:mm:ss'
  return moment(value).format(formatString)
})
Vue.filter('price', function(value) {
  const currency = store.getters.userCompany === 'USA' ? '$' : '€'
  const splittedValue = String(value)
    .replace(/,/g, '.')
    .split('.')

  if (store.getters.userCompany === 'USA') {
    return `${currency} ${splittedValue[0]}.${
      splittedValue[1] ? (String(splittedValue[1]).length < 2 ? splittedValue[1] + '0' : splittedValue[1]) : '00'
    }`
  } else {
    return `${splittedValue[0]},${
      splittedValue[1] ? (String(splittedValue[1]).length < 2 ? splittedValue[1] + '0' : splittedValue[1]) : '00'
    } ${currency}`
  }
})
Vue.filter('logoSurface', function(value) {
  const equivalence = 6.4516
  const unitMeasure = store.getters.userCompany === 'USA' ? 'in' : 'cm'
  const roundedValue = Math.round(value * 100) / 100
  const result = store.getters.userCompany === 'USA' ? roundedValue / equivalence : roundedValue
  return `${result.toFixed(1)} ${unitMeasure}`
})
Vue.filter('maxLength', function(value, maxLength) {
  if (!value || typeof value !== 'string') return '-'
  if (value.length <= maxLength) return value
  return value.substring(0, maxLength - 1) + '...'
})
Vue.filter('sizeMeasureITA2USA', function(value) {
  const equivalenceWeight = 2.205
  const equivalenceLength = 2.54
  if (value.unit.ITA.toLowerCase() === 'kg') {
    return `${(value.value * equivalenceWeight).toFixed(2)} ${value.unit.USA}`
  } else if (value.unit.ITA.toLowerCase() === 'cm') {
    return `${(value.value / equivalenceLength).toFixed(2)} ${value.unit.USA}`
  } else {
    return value.value
  }
})
Vue.filter('sizeMeasureUSA2ITA', function(value) {
  const equivalenceWeight = 2.205
  const equivalenceLength = 2.54
  if (value.unit.USA.toLowerCase() === 'lb') {
    return `${(value.value / equivalenceWeight).toFixed(2)} ${value.unit.ITA}`
  } else if (value.unit.USA.toLowerCase() === 'in') {
    return `${(value.value * equivalenceLength).toFixed(2)} ${value.unit.ITA}`
  } else {
    return value.value
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
