<template>
  <b-dropdown variant="link" size="lg" no-caret>
    <template slot="button-content">
      <div class="lang">
        <img :src="languages[selectedLang]" :alt="selectedLang" />
        <ChevronDown />
      </div>
    </template>
    <b-dropdown-item v-show="selectedLang !== 'it'" href="#" @click="changeLang('it')">
      <img :src="languages['it']" alt="it" />
    </b-dropdown-item>
    <b-dropdown-item v-show="selectedLang !== 'en'" href="#" @click="changeLang('en')">
      <img :src="languages['en']" alt="en" />
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { setupCalendar } from 'v-calendar'
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue'
import flagIT from 'svg-country-flags/svg/it.svg'
import flagEN from 'svg-country-flags/svg/gb.svg'
import flagUS from 'svg-country-flags/svg/us.svg'

export default {
  name: 'LanguageSwitch',
  components: {
    ChevronDown
  },
  data() {
    return {
      languages: {
        it: flagIT,
        en: this.$store.getters.userCompany === 'USA' ? flagUS : flagEN
      }
    }
  },
  computed: {
    selectedLang() {
      return this.$i18n.locale ? this.$i18n.locale : 'en'
    }
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang
      setupCalendar({ locale: lang })
    }
  }
}
</script>

<style scoped lang="scss">
.lang {
  float: left;
  cursor: pointer;
  color: $c-text;
  img {
    width: 40px;
    padding-right: 10px;
  }
  .material-design-icon {
    font-size: 30px;
    vertical-align: bottom;
  }
}
.dropdown-item {
  padding: 0 15px;
  &:hover,
  &:active,
  &:focus {
    background: transparent;
  }
  img {
    width: 35px;
  }
}
</style>
