<template>
  <div id="app" class="h-100">
    <div class="open-sans-force">Custom Easy</div>
    <Header v-if="$route && $route.meta && $route.meta.mainHeader" />
    <router-view />
    <div class="force-landscape" v-if="$route.name !== 'sizeYou'">
      <div class="image"></div>
      <img style="position: absolute; top: 35px; left: 15px; width: 200px;" src="@/assets/logo_sparco_white.svg" />
      <img
        style="position: absolute; top: 15px; left: 230px; width: 250px;"
        src="@/assets/logo_customeasy_oriz_white.png"
      />
    </div>
    <div class="force-large-screen" v-if="$route.name !== 'sizeYou'">
      <div class="image"></div>
      <img style="position: absolute; top: 25px; left: 15px; width: 18%;" src="@/assets/logo_sparco_white.svg" />
      <img style="position: absolute; top: 15px; left: 24%; width: 30%" src="@/assets/logo_customeasy_oriz_white.png" />
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  name: 'app',
  components: {
    Header
  }
}
</script>

<style lang="scss">
body,
html {
  font-family: 'Raleway', 'Helvetica Neue', Verdana, Arial, sans-serif;
  height: 100%;
  background: $c-grey;
  color: $c-text;
}
// Force Open Sans Download for Text Step
.open-sans-force {
  font-family: 'Open Sans';
  opacity: 0;
  width: 0;
  height: 0;
}
// Force Large Screen
.force-large-screen {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $c-blue;
  z-index: 9999;
  @media (min-width: 768px) and (orientation: portrait) {
    display: none;
  }
  @media (min-width: 992px) and (orientation: landscape) {
    display: none;
  }

  .image {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    background-size: contain;
    background-image: url(./assets/expand.gif);
  }
}

// Force Landscape
.force-landscape {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $c-blue;
  z-index: 9999;
  @media (orientation: portrait) {
    display: block;
  }

  .image {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    background-size: contain;
    background-image: url(./assets/rotate.gif);
  }
}

// Shared Classes
.fullScreenModal {
  .modal-lg {
    max-width: 98% !important;
  }
}
.b2cLink {
  text-decoration: underline;
  color: $c-blue;
  &:hover,
  &:visited,
  &:focus,
  &:active {
    color: $c-blue;
  }
}
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-default {
  cursor: default !important;
}
.cursor-disabled {
  cursor: not-allowed !important;
}
.no-select {
  user-select: none !important;
}
.Button {
  text-transform: uppercase;
  cursor: pointer;
  height: 35px;
  border: none;
  border-radius: 0;
  max-width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 6px 10px;
  margin-right: 5px;

  .material-design-icon {
    pointer-events: none;
  }

  &.disabled {
    opacity: 0.6;
    color: $c-grey3;
    cursor: default !important;
    pointer-events: none !important;
  }
  &:last-child {
    margin: 0;
  }
  &__label {
    padding-left: 5px;
  }

  &__create {
    max-width: 100%;
    margin: 0;
    color: $c-blue;
    background: $c-yellow;
  }

  &__create-outline {
    max-width: 100%;
    margin: 0;
    color: $c-blue;
    background: transparent;
    border: 1px solid $c-blue;
  }

  &__close {
    max-width: 100%;
    margin: 0;
    color: $c-white;
    background: $c-grey5;
  }

  &__delete {
    color: $c-white;
    background: $c-error;
    border: 1px solid $c-error;
  }

  &__send,
  &__view {
    color: $c-blue;
    background: $c-white;
    border: 1px solid $c-grey2;

    &--auto {
      max-width: initial;
      width: auto;
    }
  }

  &__edit {
    color: $c-white;
    background: $c-blue;
    border: 1px solid $c-blue;
  }

  &--full-width {
    max-width: 100%;
    width: 100%;
  }
}

.Page {
  &__title {
    font-size: 28px;
    padding: 0 0 5px 0;
    margin: 0 0 15px 0;
    font-weight: bold;
    border-bottom: 1px solid $c-text;
  }
}

// Bootstrap Overrides
a,
button {
  outline: none !important;
  &:hover,
  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }
}
.dropdown-menu {
  min-width: 0 !important;
}
.table {
  th,
  td:not(.text-right) {
    border: 1px solid $c-grey !important;
    text-align: center !important;
  }
  th {
    text-transform: uppercase;
    background: $c-white;
    cursor: pointer;
  }
}
.table-striped tbody tr:nth-of-type(even) {
  background-color: $c-white !important;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: $c-white2 !important;
}
.modal-content {
  border-radius: 0 !important;
  .modal-header,
  .modal-footer {
    border: none;
  }
}
.pagination {
  border-radius: 0;

  .page-item {
    .page-link {
      color: $c-text;
      background: $c-white2;
      border-radius: 0;
      &:focus {
        box-shadow: none;
      }
    }
    &.active {
      .page-link {
        color: $c-white;
        background: $c-blue;
        border: none;
      }
    }
    &.disabled {
      .page-link {
        color: $c-grey4;
      }
    }
  }
}
input {
  outline: none !important;
  border: none !important;
  &:focus {
    box-shadow: none !important;
  }
}

.popover-container {
  display: inline-block;
  width: 100% !important;

  input {
    background: $c-white !important;
    display: inline-block;
    border-radius: 0;
    padding: 5px;
  }
}

.modal-title {
  font-weight: bold;
}

.modalSize {
  .modal {
    .modal-title {
      color: $c-blue !important;
    }
  }
}

.modalWarn {
  .modal {
    .modal-title {
      color: $c-error !important;
    }
  }
}

.cell-color {
  padding-top: 100%;
  margin-bottom: 5px;
  width: 100%;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);

  @include only-xl() {
    margin-bottom: 5px;
  }
}
.start-left-side {
  background: linear-gradient(45deg, $c-blue 0%, $c-lightblue 100%);
}
.price-input {
  display: inline-block;
  margin-bottom: 2px;
  input {
    width: 80px;
    border: 1px solid $c-grey4 !important;
    border-right: none;
  }
  .input-group-text {
    padding: 1px 5px;
    color: $c-grey3;
    font-size: 18px;
    background: $c-white;
    border-radius: 0;
    border: 1px solid $c-grey4;
    border-left: none;
    padding-left: 3px;
  }
}

.square-container {
  position: relative;
}
.square-container:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}
.square-content {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
