import axios from 'axios'
import moment from 'moment'

export default {
  state: {
    lastQuery: {},
    defaultParams: {
      DEALER: {
        sortBy: 'createdAt',
        sortDir: 'DESC',
        page: 1,
        limit: 20,
        status: ['IN-PROGRESS', 'EXPIRED']
      },
      ADMIN: {
        sortBy: 'sentAt',
        sortDir: 'DESC',
        page: 1,
        limit: 20,
        status: ['SENT']
      }
    },
    queryParams: {},
    isFetching: false,
    isChangingDashboard: false,
    error: null,
    total: 0,
    items: [],
    isDeleting: [],
    selectedItem: null
  },
  getters: {
    isOrdersFetching: state => state.isFetching,
    isChangingDashboard: state => state.isChangingDashboard,
    isOrderDeleting: state => id => state.isDeleting.includes(id),
    isSomeOrderDeleting: state => state.isDeleting.length > 0,
    ordersDefaultParams: (state, getters) => {
      const role = getters.loggedUser && getters.loggedUser.role ? getters.loggedUser.role : 'DEALER'
      if (state.defaultParams[role]) {
        // Default company filter for ADMINs
        return getters.isAdmin
          ? { ...state.defaultParams[role], company: [getters.userCompany] }
          : { ...state.defaultParams[role] }
      } else return {}
    },
    orderSelectedItem: state => state.selectedItem || {},
    orders: state => state.items,
    ordersTotal: state => state.total,
    ordersError: state => state.error,
    ordersLastQuery: state => state.lastQuery
  },
  mutations: {
    ORDERS_IS_FETCHING(state) {
      state.isFetching = true
      state.error = null
    },
    ORDERS_IS_CHANGING_DASHBOARD(state, value) {
      state.isChangingDashboard = Boolean(value)
      state.queryParams = {}
    },
    ORDERS_IS_NOT_FETCHING(state) {
      state.isFetching = false
      state.error = null
    },
    ORDERS_GET_LIST_SUCCESS(state, payload) {
      state.isFetching = false
      state.isChangingDashboard = false
      state.error = null
      state.queryParams = {
        ...state.queryParams,
        ...Object.keys(payload.params).reduce((res, e) => {
          if (e === 'status' && typeof payload.params[e] === 'string') {
            res[e] = payload.params[e].split(',')
          } else {
            res[e] = payload.params[e]
          }
          return res
        }, {})
      }
      state.items = payload.data.items
      state.total = payload.data.total
    },
    ORDERS_GET_ITEM_SUCCESS(state, payload) {
      state.isFetching = false
      state.error = null
      state.selectedItem = payload
    },
    ORDERS_ERROR(state, payload) {
      state.isFetching = false
      state.error = payload || true
      state.items = []
      state.total = 0
    },
    ORDERS_SET_IS_DELETING(state, payload) {
      if (payload.value === true) {
        state.isDeleting = [...state.isDeleting, payload.id]
      } else {
        state.isDeleting = state.isDeleting.filter(x => x !== payload.id)
      }
    },
    ORDERS_SET_LAST_QUERY(state, payload) {
      state.lastQuery = payload
    },
    ORDERS_UPDATE_SELECTED_ITEM(state, payload) {
      state.selectedItem = {
        ...state.selectedItem,
        ...payload
      }
    },
    AUTH_LOGOUT(state) {
      state.queryParams = {}
      state.isFetching = false
      state.error = null
      state.total = 0
      state.items = []
      state.isDeleting = []
      state.selectedItem = null
    }
  },
  actions: {
    ORDERS_IS_CHANGING_DASHBOARD({ commit }, value) {
      commit('ORDERS_IS_CHANGING_DASHBOARD', value)
    },
    ORDERS_GET_LIST({ commit }, params) {
      return new Promise(resolve => {
        commit('ORDERS_IS_FETCHING')
        const queryString = Object.keys(params)
          .reduce((res, e) => [...res, e + '=' + params[e].toString()], [])
          .join('&')

        axios
          .get(process.env.VUE_APP_API_BASE_URL + '/orders?' + queryString)
          .then(res => {
            commit('ORDERS_GET_LIST_SUCCESS', { data: res.data, params })
            resolve()
          })
          .catch(error => {
            const code =
              error.response && error.response.data && error.response.data.code ? error.response.data.code : ''
            commit('ORDERS_ERROR', code)
            resolve()
          })
      })
    },
    ORDERS_GET_ITEM({ commit }, id) {
      return new Promise(resolve => {
        commit('ORDERS_IS_FETCHING')
        axios
          .get(process.env.VUE_APP_API_BASE_URL + '/orders/' + id)
          .then(res => {
            commit('ORDERS_GET_ITEM_SUCCESS', res.data)
            resolve()
          })
          .catch(error => {
            const code =
              error.response && error.response.data && error.response.data.code ? error.response.data.code : ''
            commit('ORDERS_ERROR', code)
            resolve()
          })
      })
    },
    ORDERS_CREATE({ commit }) {
      return new Promise(resolve => {
        commit('ORDERS_IS_FETCHING')
        axios
          .post(process.env.VUE_APP_API_BASE_URL + '/orders')
          .then(res => {
            resolve(res.data)
          })
          .catch(error => {
            commit('ORDERS_ERROR', error.response.data.code)
            resolve(false)
          })
      })
    },
    ORDERS_SET_IS_DELETING({ commit }, payload) {
      commit('ORDERS_SET_IS_DELETING', payload)
    },
    ORDERS_DELETE({ dispatch, commit, state }, id) {
      return new Promise(resolve => {
        commit('ORDERS_IS_FETCHING')
        axios
          .delete(process.env.VUE_APP_API_BASE_URL + '/orders/' + id)
          .then(() => {
            dispatch('ORDERS_GET_LIST', state.queryParams).then(() => {
              commit('ORDERS_SET_IS_DELETING', { id, value: false })
              resolve()
            })
          })
          .catch(error => {
            commit('ORDERS_SET_IS_DELETING', { id, value: false })
            commit('ORDERS_ERROR', error.response.data.code)
            resolve()
          })
      })
    },
    ORDERS_DELETE_FORCE({ dispatch, commit, state }, id) {
      return new Promise(resolve => {
        commit('ORDERS_IS_FETCHING')
        axios
          .delete(process.env.VUE_APP_API_BASE_URL + '/orders/' + id + '?force=true')
          .then(() => {
            commit('ORDERS_SET_IS_DELETING', { id, value: false })
            dispatch('ORDERS_GET_LIST', state.queryParams).then(() => {
              commit('ORDERS_SET_IS_DELETING', { id, value: false })
              resolve()
            })
          })
          .catch(error => {
            commit('ORDERS_SET_IS_DELETING', { id, value: false })
            commit('ORDERS_ERROR', error.response.data.code)
            resolve()
          })
      })
    },
    ORDERS_SEND({ dispatch, commit, state }, id) {
      commit('ORDERS_IS_FETCHING')
      axios
        .put(process.env.VUE_APP_API_BASE_URL + '/orders/send/' + id)
        .then(() => {
          dispatch('ORDERS_GET_LIST', state.queryParams)
        })
        .catch(error => {
          commit('ORDERS_ERROR', error.response.data.code)
        })
    },
    ORDERS_CLOSE({ dispatch, commit, state }, id) {
      commit('ORDERS_IS_FETCHING')
      axios
        .put(process.env.VUE_APP_API_BASE_URL + '/orders/close/' + id)
        .then(() => {
          dispatch('ORDERS_GET_LIST', state.queryParams)
        })
        .catch(error => {
          commit('ORDERS_ERROR', error.response.data.code)
        })
    },
    ORDERS_ROLLBACK({ dispatch, commit, state }, id) {
      commit('ORDERS_IS_FETCHING')
      axios
        .put(process.env.VUE_APP_API_BASE_URL + '/orders/rollback/' + id)
        .then(() => {
          dispatch('ORDERS_GET_LIST', state.queryParams)
        })
        .catch(error => {
          commit('ORDERS_ERROR', error.response.data.code)
        })
    },
    ORDERS_CLONE({ commit }, id) {
      return new Promise(resolve => {
        commit('ORDERS_IS_FETCHING')
        axios
          .put(process.env.VUE_APP_API_BASE_URL + '/orders/clone/' + id)
          .then(res => {
            resolve(res.data)
          })
          .catch(error => {
            commit('ORDERS_ERROR', error.response.data.code)
            resolve(false)
          })
      })
    },
    ORDERS_UPDATE_CONFIGURATION({ commit }, payload) {
      return new Promise(resolve => {
        commit('ORDERS_IS_FETCHING')
        axios
          .put(process.env.VUE_APP_API_BASE_URL + '/orders/configuration/' + payload.id, {
            configuration: payload.configuration,
            quantity: payload.quantity || 1,
            notes: payload.notes || '',
            reference: payload.reference || '',
            pdf: payload.pdf
          })
          .then(res => {
            commit('ORDERS_GET_ITEM_SUCCESS', res.data)
            resolve()
          })
          .catch(error => {
            commit('ORDERS_ERROR', error.response.data.code)
          })
      })
    },
    ORDERS_GET_PDF({ commit }, id) {
      return new Promise(resolve => {
        commit('ORDERS_IS_FETCHING')
        axios
          .get(process.env.VUE_APP_API_BASE_URL + '/orders/' + id + '/pdf', { responseType: 'arraybuffer' })
          .then(res => {
            commit('ORDERS_IS_NOT_FETCHING')
            resolve(res.data)
          })
          .catch(error => {
            commit('ORDERS_ERROR', error.response.data.code)
          })
      })
    },
    ORDERS_GET_ZIP({ commit }, id) {
      return new Promise(resolve => {
        commit('ORDERS_IS_FETCHING')
        axios
          .get(process.env.VUE_APP_API_BASE_URL + '/orders/' + id + '/zip?t=' + moment().unix())
          .then(res => {
            commit('ORDERS_IS_NOT_FETCHING')
            resolve(res.data)
          })
          .catch(error => {
            commit('ORDERS_ERROR', error.response.data.code)
          })
      })
    },
    //carico la decal nello storage
    ORDERS_UPLOAD_DECAL({ commit }, data) {
      //data: {orderId, decal: {type, data: base64}}
      return new Promise(resolve => {
        commit('ORDERS_IS_FETCHING')
        axios
          .post(process.env.VUE_APP_API_BASE_URL + '/public/orders/' + data.orderId + '/logo', data.decal)
          .then(res => {
            commit('ORDERS_IS_NOT_FETCHING')
            resolve(res.data)
          })
          .catch(error => {
            commit('ORDERS_ERROR', error.response.data.code)
          })
      })
    },
    ORDERS_COPY_DECAL_FROM_LIBRARY({ commit }, data) {
      //data: {orderId, decal}
      return new Promise(resolve => {
        commit('ORDERS_IS_FETCHING')
        axios
          .post(process.env.VUE_APP_API_BASE_URL + '/public/orders/' + data.orderId + '/logo/copy/' + data.decal)
          .then(res => {
            commit('ORDERS_IS_NOT_FETCHING')
            resolve(res.data)
          })
          .catch(error => {
            commit('ORDERS_ERROR', error.response.data.code)
          })
      })
    },
    ORDERS_CONFIRM_B2C({ dispatch, commit, state }, id) {
      commit('ORDERS_IS_FETCHING')
      axios
        .put(process.env.VUE_APP_API_BASE_URL + '/orders/confirm/' + id)
        .then(() => {
          dispatch('ORDERS_GET_LIST', state.queryParams)
        })
        .catch(error => {
          commit('ORDERS_ERROR', error.response.data.code)
        })
    },
    ORDERS_SET_LAST_QUERY({ commit }, data) {
      commit('ORDERS_SET_LAST_QUERY', data)
    },
    ORDERS_RESET_SELECTED({ commit }) {
      commit('ORDERS_GET_ITEM_SUCCESS', null)
    },
    ORDERS_UPDATE_SELECTED_ITEM({ commit }, data) {
      commit('ORDERS_UPDATE_SELECTED_ITEM', data)
    }
  }
}
