import axios from 'axios'

const CATALOG_UPDATE_WARNING_MODAL_SHOW = false //TODO: use this to show the catalog update waring modal by default

export default {
  state: {
    modalWarning: CATALOG_UPDATE_WARNING_MODAL_SHOW,
    skipNavigationAlert: false,
    stdSizeCustomization: false,
    isFetching: false,
    error: null,
    basePrice: {},
    configParams: {},
    viewAngle: 'front',
    views: {},
    viewTransformation: {},
    selectedColorArea: 0,
    logoModes: ['PRINT', 'EMBROIDERY'],
    currentLogoMode: 0,
    configuratorSteps: [
      {
        id: 'design',
        label: 'CFG_MENU_DESIGN',
        viewsNavigation: true
      },
      {
        id: 'style',
        label: 'CFG_MENU_STYLE',
        viewsNavigation: true
      },
      {
        id: 'color',
        label: 'CFG_MENU_COLOR',
        viewsNavigation: true
      },
      {
        id: 'text',
        label: 'CFG_MENU_TEXT',
        viewsNavigation: false
      },
      {
        id: 'size',
        label: 'CFG_MENU_SIZE',
        viewsNavigation: true
      },
      {
        id: 'logo',
        label: 'CFG_MENU_LOGO',
        viewsNavigation: false
      }
    ],
    prevConfigState: null,
    //indica a che punto sono della configurazione
    configState: 'design',
    //indica il modello attuale e contiene la configurazione di tutti i suoi stili
    actualModel: null,
    //indica l'indice dello stile selezionato
    actualStyle: 0,
    //lista dei modelli
    models: [],
    styles: [],
    //libreria dei materiali
    materialLibrary: [],
    /* decals info */
    //riferimento all'area logo selezionata
    actualDecalArea: null,
    //
    decalsStatus: {
      //se true, indica che il modello è allineato al canvas
      updated: true,
      //indica il tipo di stato (add, remove....)
      type: null,
      //indica il messaggio
      payload: null
    }
  },
  getters: {
    showModalWarning: state => state.modalWarning,
    isConfiguratorFetching: state => state.isFetching,
    configuratorError: state => state.error,
    configuratorSteps: state => state.configuratorSteps,
    configuratorPreviousStep: state => state.configuratorSteps.find(x => x.id === state.prevConfigState),
    configuratorCurrentStep: state =>
      state.configuratorSteps.find(x => x.id === state.configState) || { viewsNavigation: true },
    configuratorCurrentStepIndex: (state, getters) => {
      const currentStepIndex = getters.configuratorSteps
        .map((x, i) => ({ ...x, position: i }))
        .find(x => x.id === getters.configuratorCurrentStep.id)
      return currentStepIndex ? currentStepIndex.position : -1
    },
    configuratorStepsIsPrevAvailable: (state, getters) => {
      const currentStepIndex = getters.configuratorCurrentStepIndex
      return Boolean(currentStepIndex > 0)
    },
    configuratorStepsIsNextAvailable: (state, getters) => {
      const currentStepIndex = getters.configuratorCurrentStepIndex
      return Boolean(currentStepIndex < getters.configuratorSteps.length - 1)
    },
    configuratorModels: state => state.models,
    configuratorActualModel: state => state.actualModel,
    configuratorIntroMaterials: state => state.materialLibrary.filter(x => x.intro === true),
    configuratorViews: state => state.views,
    configuratorCurrentView: state => state.views[state.viewAngle],
    configuratorCurrentViewAngle: state => state.viewAngle,
    configuratorViewTransformation: state => state.viewTransformation,
    currentStyle: state => (state.actualModel ? { ...state.actualModel.styles[state.actualStyle] } : {}),
    currentStyleAreas: state => (state.actualModel ? [...state.actualModel.styles[state.actualStyle].areas] : []),
    defaultStyleAreas: state => idx => (state.styles[idx] ? state.styles[idx].areas : []),
    configuratorIsBeltAvailable: state =>
      state.actualModel ? state.actualModel.styles[state.actualStyle].beltAvailability || false : false,
    configuratorBelt: state =>
      state.actualModel
        ? {
            ...state.actualModel.belt,
            beltArea: state.actualModel.styles[state.actualStyle].beltArea
          }
        : {},
    configuratorIsPocketAvailable: state =>
      state.actualModel ? state.actualModel.styles[state.actualStyle].pocketAvailability || false : false,
    configuratorPocket: state => (state.actualModel ? state.actualModel.pocket : false),
    configuratorNavigatorPocket: state => (state.actualModel ? state.actualModel.navigatorPocket : false),
    configuratorAreNascarCuffsAvailable: state =>
      state.actualModel ? state.actualModel.styles[state.actualStyle].nascarCuffsAvailability || false : false,
    configuratorNascarCuffs: state => (state.actualModel ? state.actualModel.nascarCuffs || false : false),
    configuratorSelectedColorArea: state => state.selectedColorArea,
    configuratorStandardSizes: state =>
      state.actualModel ? Object.keys(state.actualModel.size.template.standard) : [],
    configuratorIsCustomSizeAvailable: state =>
      state.actualModel ? state.actualModel.size.template.hasOwnProperty('customized') : false,
    configuratorIsNavigatorPocketAvailable: state =>
      state.actualModel ? state.actualModel.styles[state.actualStyle].navigatorPocketAvailability || false : false,
    //metodo che controlla se lo stato in input è lo stato corrente
    isCurrentState: state => data => {
      return state.configState === data
    },

    //metodo che restituisce i dati della cintura
    getBeltConfiguration: state => {
      return state.actualModel ? state.actualModel.belt : {}
    },
    //metodo che restituisce il colore in base al codice materiale
    getColorByMaterial: state => codMat => (state.materialLibrary.find(e => e.code === codMat) || {}).color,
    //metodo che restituisce il materiale in base al codice
    getMaterialByCode: state => codMat => state.materialLibrary.find(e => e.code === codMat) || {},
    //metodo per ricavare l'attuale configurazione delle taglie
    actualSize: state => {
      return state.actualModel ? state.actualModel.size.actualSize : {}
    },

    //metodo per impostare la visualizzazione del genere sulla taglia personalizzata
    configuratorIsGenderAvailable: state => {
      return state.actualModel ? state.actualModel.size.genderAvailability || false : false
    },

    //metodo per impostare la visualizzazione del genere sulla taglia personalizzata
    configuratorDefaultGender: state => {
      return state.actualModel ? state.actualModel.size.genderDefault : 'M'
    },
    getStandardTemplateSize: state => size => {
      return state.actualModel ? state.actualModel.size.template.standard[size] : []
    },
    //metodo per ottenere lo stato dei dati delle decals/modello dati
    getDecalsStatus: state => {
      return state.decalsStatus
    },
    //metodo per ottenere tutte le customized logo part
    getDecalAreaList: state => {
      return state.actualModel ? state.actualModel.styles[state.actualStyle].customizedLogo : []
    },
    //metodo che controlla se esistono copie della decal in input
    hasCopy: state => id => {
      let obj = state.actualDecalArea.logos.find(value => {
        return value.id === id
      })
      let response = 0
      state.actualDecalArea.logos.forEach(element => {
        if (element.name === obj.name) {
          response++
        }
      })
      return response > 1 ? true : false
    },
    //metodo che restituisce le informazioni del logo in base all'id
    getDecalInfo: state => id => {
      return state.actualDecalArea.logos.find(value => {
        return value.id === id
      })
    },
    getCreditCost: (state, getters) => {
      let result = 0
      const logoMode = state.logoModes[state.currentLogoMode]
      if (
        logoMode &&
        state.configParams.pricing &&
        state.configParams.pricing.creditCost[logoMode] &&
        state.configParams.pricing.creditCost[logoMode][getters.userCompany]
      ) {
        result = state.configParams.pricing.creditCost[logoMode][getters.userCompany]
      }
      return result
    },
    getNumLogo: state =>
      state.actualModel
        ? state.actualModel.styles[state.actualStyle].customizedLogo.reduce((res, x) => res + x.logos.length, 0)
        : 0,
    getDecalCredits: state => {
      return state.actualModel && state.configParams.pricing
        ? state.actualModel.styles[state.actualStyle].customizedLogo
            .map(x =>
              x.logos
                .map(l => {
                  const a = state.configParams.pricing.logoSurface.find(
                    element =>
                      Math.round(element.from) <= Math.round(l.cm2) && Math.round(element.to) >= Math.round(l.cm2)
                  )
                  return a
                    ? a.credit
                    : state.configParams.pricing.logoSurface[state.configParams.pricing.logoSurface.length - 1].credit
                })
                .reduce((a, b) => a + b, 0)
            )
            .reduce((a, b) => a + b, 0)
        : 0
    },
    getDigitalizationPrice: (state, getters) => {
      return state.actualModel && state.configParams.pricing
        ? state.actualModel.styles[state.actualStyle].customizedLogo
            .map(x =>
              x.logos
                .map(l => {
                  const a = state.configParams.pricing.digitalization.find(
                    element => element.from < l.cm2 && element.to >= l.cm2
                  )
                  if (state.configParams.pricing.digitalization.length === 0) return 0
                  return a
                    ? a.unitCost[getters.userCompany] * l.cm2
                    : state.configParams.pricing.digitalization[state.configParams.pricing.digitalization.length - 1]
                        .unitCost[getters.userCompany] * l.cm2
                })
                .reduce((a, b) => a + b, 0)
            )
            .reduce((a, b) => a + b, 0)
        : 0
    },
    logosPrice: (state, getters) => (discountPercentage = 0) => {
      const result = { logoSurface: getters.getDecalCredits, digitalization: getters.getDigitalizationPrice }
      if (state.configParams.pricing) {
        // Logo Surface Free Credits
        result.logoSurface =
          Math.max(0, result.logoSurface - state.configParams.pricing.includedFreeCredits) * getters.getCreditCost
        // Apply Discount Percentage
        const discount = discountPercentage / 100
        result.logoSurface = result.logoSurface - result.logoSurface * discount
        result.digitalization = result.digitalization - result.digitalization * discount
      }
      return result
    },
    isCustomizedSize: (state, getters) => {
      const currentValues = getters.actualSize ? getters.actualSize.measures : []
      const templateValues =
        getters.actualSize && getters.configuratorActualModel
          ? getters.configuratorActualModel.size.template['standard'][getters.actualSize.measure]
          : []

      return (
        getters.actualSize &&
        (getters.actualSize.type !== 'standard' ||
          currentValues
            .map((x, i) => templateValues[i] && Number(x.value) === Number(templateValues[i].value))
            .some(x => x !== true))
      )
    },
    sizePrice: (state, getters) => (discountPercentage = 0) => {
      let result = 0
      if (getters.isCustomizedSize && state.configParams.pricing && getters.userCompany) {
        const discount = discountPercentage / 100
        if (
          state.configParams.pricing.customizedSizePrice[getters.actualSize.type] &&
          state.configParams.pricing.customizedSizePrice[getters.actualSize.type][getters.userCompany]
        ) {
          result = state.configParams.pricing.customizedSizePrice[getters.actualSize.type][getters.userCompany]
          result = result - result * discount
        }
      }
      return result
    },
    currentModelPrice: (state, getters) => (discountPercentage = 0) => {
      if (getters.userCompany && state.basePrice && state.basePrice[getters.userCompany]) {
        const discount = discountPercentage / 100
        return state.basePrice[getters.userCompany] - state.basePrice[getters.userCompany] * discount
      }
      return 0
    },
    nascarCuffsPrice: (state, getters) => (discountPercentage = 0) => {
      let result = 0
      if (getters.configuratorNascarCuffs === true) {
        const discount = discountPercentage / 100

        if (
          state.configParams.pricing &&
          state.configParams.pricing.nascarCuffs &&
          state.configParams.pricing.nascarCuffs[getters.userCompany]
        ) {
          result = state.configParams.pricing.nascarCuffs[getters.userCompany]
          result = result - result * discount
        }
      }
      return result
    },
    isStandardSizeCustomized: (state, getters) => state.stdSizeCustomization && getters.userCompany === 'ITA',
    isSizeValid: (state, getters) => {
      if (getters.actualSize && getters.actualSize.measures) {
        return getters.actualSize.type === 'standard' || getters.actualSize.measures.every(x => !!Number(x.value))
      }
      return true
    },
    logoModeAvailable: state => (state.actualModel && state.actualModel.logoMode ? state.actualModel.logoMode : [])
  },
  mutations: {
    CONFIGURATOR_CATALOG_UPDATE_WARNING(state, value) {
      state.modalWarning = Boolean(value)
    },
    CONFIGURATOR_RESET_CATALOG_UPDATE_WARNING(state) {
      state.modalWarning = Boolean(CATALOG_UPDATE_WARNING_MODAL_SHOW)
    },
    CONFIGURATOR_SKIP_NAVIGATION_ALERT(state, value) {
      state.skipNavigationAlert = Boolean(value)
    },
    CONFIGURATOR_IS_FETCHING(state, value = true) {
      state.isFetching = value
      state.error = null
    },
    CONFIGURATOR_ERROR(state) {
      state.error = true
    },
    //metodo observable per tenere aggiornato il componente viewer
    CONFIGURATOR_UPDATE_DATA(/*state, bool*/) {
      // state.updated = bool
    },
    //metodo che cambia lo stato della configurazione
    CONFIGURATOR_CHANGE_STATE(state, status) {
      state.prevConfigState = state.configState
      state.configState = status
    },
    /*--- STILE---*/
    CONFIGURATOR_SELECT_STYLE(state, index) {
      state.actualStyle = index
      state.selectedColorArea = 0
      state.views = state.actualModel.styles[index].views
    },
    //metodo che colora l'area con il materiale richiesto
    CONFIGURATOR_SET_COLOR(state, data) {
      // data: { area: indice dell'area, material: materiale}
      if (state.actualModel) {
        state.actualModel.styles = state.actualModel.styles.map((style, idx) => {
          if (state.actualStyle !== idx) return { ...style }
          return {
            ...style,
            areas: style.areas.map((area, i) => {
              if (i !== data.area) return area
              return {
                ...area,
                selectedMaterial: data.material
              }
            })
          }
        })
      }
    },
    /*---LIBRERIA DEI MATERIALI---*/
    CONFIGURATOR_SET_MATERIAL_LIBRARY(state, library) {
      state.materialLibrary = library
    },
    /*---MODELLO---*/
    //metodo che importa la configurazione della tuta
    CONFIGURATOR_IMPORT(state, payload) {
      state.actualStyle = payload.configuration.actualStyle
      state.views = { ...payload.configuration.styles.views }
      state.stdSizeCustomization = Boolean(payload.configuration.stdSizeCustomization)

      if (
        payload.configuration.currentLogoMode !== null &&
        payload.configuration.currentLogoMode !== undefined &&
        Object.prototype.hasOwnProperty.call(state.actualModel.logoMode, payload.configuration.currentLogoMode)
      ) {
        state.currentLogoMode = payload.configuration.currentLogoMode
      } else {
        state.currentLogoMode = state.logoModes.indexOf(state.actualModel.logoMode[0].toUpperCase())
      }

      // Remap current model taking values from both data and saved configuration....bad choice to manage data!!
      state.actualModel = {
        ...payload.configuration,
        name: state.actualModel.name,
        description: state.actualModel.description,
        homologation: state.actualModel.homologation || null,
        logoMode: state.actualModel.logoMode,
        belt: {
          ...payload.configuration.belt,
          canvas: { ...state.actualModel.belt.canvas },
          pdf: { ...state.actualModel.belt.pdf },
          transform: { ...state.actualModel.belt.transform }
        },
        pocket: payload.configuration.pocket,
        navigatorPocket: payload.configuration.navigatorPocket,
        nascarCuffs: Boolean(payload.configuration.nascarCuffs),
        // Remap sizes...values from data...current value from config
        size: {
          ...state.actualModel.size,
          actualSize: {
            ...payload.configuration.size.actualSize,
            measures:
              payload.configuration.size.actualSize.type === 'customized'
                ? state.actualModel.size.template[payload.configuration.size.actualSize.type].map(x => {
                    const result = { ...x }
                    const savedValue = payload.configuration.size.actualSize.measures.find(m => m.name === x.name)
                    if (savedValue) {
                      result.value = savedValue.value
                    }
                    return result
                  })
                : state.actualModel.size.template[payload.configuration.size.actualSize.type][
                    payload.configuration.size.actualSize.measure
                  ].map(x => {
                    const result = { ...x }
                    const savedValue = payload.configuration.size.actualSize.measures.find(m => m.name === x.name)
                    if (savedValue) {
                      result.value = savedValue.value
                    }
                    return result
                  })
          }
        },
        // Remap styles...values from data...current values (selected color) from config
        styles: state.actualModel.styles.map((x, i) => ({
          ...x,
          areas: i === payload.configuration.actualStyle ? [...payload.configuration.styles.areas] : x.areas,
          customizedLogo: x.customizedLogo.map((cl, index) => {
            if (i !== payload.configuration.actualStyle) {
              return { ...cl }
            } else {
              return {
                ...cl,
                config: { ...payload.configuration.styles.customizedLogo[index].config },
                logos: [...payload.configuration.styles.customizedLogo[index].logos]
              }
            }
          })
        }))
      }
    },
    //ottiene la lista di tutti i modelli
    CONFIGURATOR_SET_ALLMODELS(state, models) {
      state.models = models
    },
    //seleziona lo specifico modello
    CONFIGURATOR_SELECT_MODEL(state, model) {
      state.basePrice = {
        ITA: model.priceITA,
        USA: model.priceUSA
      }
      state.actualStyle = 0
      state.actualModel = model
      state.styles = [...model.styles]
      state.views = model.styles[0].views
      state.selectedColorArea = 0
      state.isFetching = false

      state.currentLogoMode = state.logoModes.indexOf(state.actualModel.logoMode[0].toUpperCase())
    },
    /*---CINTURA--- */
    //metodo che attiva o disattiva la cintura
    CONFIGURATOR_TOGGLE_BELT(state, bool) {
      // Vue.set(state.actualModel.belt, 'show', bool) // Dirt but working....
      state.actualModel = {
        ...state.actualModel,
        belt: {
          ...state.actualModel.belt,
          show: bool
        }
      }
    },
    //metodo che cambia i parametri della cintura
    CONFIGURATOR_SET_BELT(state, data) {
      //data {'option': 'name', 'value': 'm.rossi'}
      state.actualModel.belt[data.option] = data.value ? data.value : ''
    },
    /*---TASCA---*/
    CONFIGURATOR_SET_POCKET(state, bool) {
      state.actualModel = {
        ...state.actualModel,
        pocket: bool
      }
    },
    /*---TASCA NAVIGATORE---*/
    CONFIGURATOR_SET_NAVIGATOR_POCKET(state, bool) {
      state.actualModel = {
        ...state.actualModel,
        navigatorPocket: bool
      }
    },
    /*---NASCAR---*/
    CONFIGURATOR_SET_NASCAR_CUFFS(state, bool) {
      state.actualModel = {
        ...state.actualModel,
        nascarCuffs: bool
      }
    },
    /*---TAGLIE--*/
    CONFIGURATOR_CHANGE_SIZETYPE(state, payload) {
      if (payload.type === 'standard') {
        const stdMesure = payload.measure ? payload.measure : Object.keys(state.actualModel.size.template.standard)[0]
        state.actualModel.size.actualSize = {
          type: 'standard',
          measure: stdMesure,
          measures: JSON.parse(JSON.stringify(state.actualModel.size.template.standard[stdMesure]))
        }
      } else if (payload.type === 'customized') {
        state.actualModel.size.actualSize = {
          type: 'customized',
          measure: null,
          measures: JSON.parse(JSON.stringify(state.actualModel.size.template.customized))
        }
      }
    },
    CONFIGURATOR_CHANGE_SIZEGENDER(state, payload) {
      state.actualModel.size.actualSize = {
        ...state.actualModel.size.actualSize,
        gender: payload
      }
    },
    CONFIGURATOR_SET_SIZEYOU_MEASURES(state, data) {
      state.actualModel.size.actualSize.sizeYou = data.user_data
      state.actualModel.size.actualSize.measures = state.actualModel.size.actualSize.measures.map(x => {
        let measureId = -1
        switch (x.name) {
          case 'CFG_SIZE_ALTEZZA':
            measureId = '1'
            break
          case 'CFG_SIZE_PESO':
            measureId = '2'
            break
          case 'CFG_SIZE_TORACE':
            measureId = '3'
            break
          case 'CFG_SIZE_VITA':
            measureId = '4'
            break
          case 'CFG_SIZE_BACINO':
            measureId = '5'
            break
          case 'CFG_SIZE_COSCIA':
            measureId = '6'
            break
          case 'CFG_SIZE_SPALLE':
            measureId = '7'
            break
          case 'CFG_SIZE_MANICA':
            measureId = '8'
            break
          case 'CFG_SIZE_LARGHEZZA_GAMBA':
            measureId = '9'
            break
          case 'CFG_SIZE_GAMBA_ESTERNO':
            measureId = '10'
            break
          case 'CFG_SIZE_GOMITO':
            measureId = '11'
            break
          case 'CFG_SIZE_DIETRO':
            measureId = '12'
            break
          case 'CFG_SIZE_CAVALLO_DIETRO':
            measureId = '13'
            break
          case 'CFG_SIZE_BICIPITE':
            measureId = '14'
            break
          case 'CFG_SIZE_FONDO_MANICA':
            measureId = '15'
            break
        }
        const measure = measureId > 0 ? data.user_measures.find(e => e.measure_id === measureId) : null

        return { ...x, value: measure ? measure.measure_value : '' }
      })
    },
    CONFIGURATOR_CHANGE_STANDARDSIZE(state, size) {
      state.actualModel.size.actualSize.measure = size
      state.actualModel.size.actualSize.measures = JSON.parse(
        JSON.stringify(state.actualModel.size.template.standard[size])
      )
    },
    //metodo che modifica uno specifico valore della taglia attuale
    CONFIGURATOR_SET_SIZE(state, data) {
      //data -> { measure: 'peso', value '6'}
      var i = state.actualModel.size.actualSize.measures.findIndex(element => {
        return element.name === data.measure
      })
      state.actualModel.size.actualSize.measures[i].value = data.value
    },
    /*---DECALS---*/
    CONFIGURATOR_SAVE_CONFIG(state, config) {
      state.actualDecalArea.config = {
        ...config,
        objects: {
          ...config.objects,
          objects: config.objects.objects.map(x => ({
            ...x,
            src: (state.actualDecalArea.logos.find(e => e.id === x.id) || {}).src
          }))
        }
      }
    },
    CONFIGURATOR_SELECT_DECAL_AREA(state, index) {
      state.actualDecalArea = state.actualModel.styles[state.actualStyle].customizedLogo[index]
    },
    //metodo NotifyObservers (pattern obs)
    CONFIGURATOR_DECAL_UPDATING(state, data) {
      state.decalsStatus.updated = false
      state.decalsStatus.type = data.type
      state.decalsStatus.payload = data.payload
    },
    //metodo che aggiunge nell'elenco dei loghi l'id generato dal canvas
    CONFIGURATOR_ADD_DECAL(state, decal) {
      if (state.decalsStatus.type === 'add') {
        state.actualDecalArea.logos.push(decal)
        //update done
        state.decalsStatus.updated = true
        state.decalsStatus.type = null
        state.decalsStatus.payload = null
      }
    },
    //metodo che rimuove il logo in base
    CONFIGURATOR_REMOVE_DECAL(state, id) {
      if (state.decalsStatus.type === 'remove') {
        var index = state.actualDecalArea.logos.findIndex(element => {
          return element.id === id
        })
        state.actualDecalArea.logos.splice(index, 1)
        //update done
        state.decalsStatus.updated = true
        state.decalsStatus.type = null
        state.decalsStatus.payload = null
      }
    },
    //metodo che aggiunge i cm2 allo specifico logo
    CONFIGURATOR_UPDATE_CM2(state, data) {
      //data: {id, cm2}
      //trovo la posizione dell'immagine
      const index = state.actualDecalArea.logos.findIndex(element => {
        return element.id === data.id
      })
      //aggiungo i cm2 al dato
      state.actualDecalArea.logos[index].cm2 = data.cm2
    },

    //metodo generico per specificare di essere allineati
    CONFIGURATOR_ISUPDATED(state) {
      //update done
      state.decalsStatus.updated = true
      state.decalsStatus.type = null
      state.decalsStatus.payload = null
    },
    CONFIGURATOR_SELECT_COLOR_AREA(state, value) {
      state.selectedColorArea = value
    },
    CONFIGURATOR_INITAL_VALUES(state) {
      state.isFetching = false
      state.basePrice = {}
      state.error = null
      state.viewAngle = 'front'
      state.views = {}
      state.configParams = {}
      state.selectedColorArea = 0
      state.prevConfigState = null
      state.configState = 'design'
      state.actualModel = null
      state.actualStyle = 0
      state.models = []
      state.styles = []
      state.materialLibrary = []
      state.actualDecalArea = null
      state.decalsStatus = {
        updated: true,
        type: null,
        payload: null
      }
      state.currentLogoMode = 0
    },
    CONFIGURATOR_SET_VIEW_ANGLE(state, angle) {
      if (state.viewAngle !== angle) {
        state.viewAngle = angle
      }
    },
    CONFIGURATOR_SET_VIEW_TRANSFORMATION(state, payload) {
      state.viewTransformation = payload
    },
    CONFIGURATOR_SETTINGS_UPDATE(state, payload) {
      state.configParams = { ...payload }
    },
    CONFIGURATOR_SET_STDSIZE_CUSTOMIZATION(state, value) {
      state.stdSizeCustomization = Boolean(value)
    },
    CONFIGURATOR_SET_LOGO_MODE(state, value) {
      state.currentLogoMode = Number(value)
    }
  },
  actions: {
    CONFIGURATOR_GET_SETTINGS({ commit }) {
      return new Promise(resolve => {
        commit('CONFIGURATOR_IS_FETCHING')
        axios
          .get(process.env.VUE_APP_API_BASE_URL + '/public/settings')
          .then(res => {
            commit('CONFIGURATOR_SETTINGS_UPDATE', res.data)
            resolve()
          })
          .catch(() => {
            commit('CONFIGURATOR_ERROR')
            resolve()
          })
      })
    },
    CONFIGURATOR_GET_CATALOG({ commit }, company) {
      return new Promise(resolve => {
        commit('CONFIGURATOR_IS_FETCHING')
        const requests = [
          axios.get(process.env.VUE_APP_API_BASE_URL + '/public/catalog/models/company/' + company),
          axios.get(process.env.VUE_APP_API_BASE_URL + '/public/catalog/materials'),
          axios.get(process.env.VUE_APP_API_BASE_URL + '/public/settings')
        ]

        Promise.all(requests)
          .then(res => {
            commit('CONFIGURATOR_SET_ALLMODELS', res[0].data)
            commit('CONFIGURATOR_SET_MATERIAL_LIBRARY', res[1].data)
            commit('CONFIGURATOR_SETTINGS_UPDATE', res[2].data)
            resolve()
          })
          .catch(() => {
            commit('CONFIGURATOR_ERROR')
            resolve()
          })
      })
    },
    CONFIGURATOR_SELECT_MODEL({ commit }, modelSku) {
      return new Promise(resolve => {
        commit('CONFIGURATOR_IS_FETCHING')
        axios
          .get(process.env.VUE_APP_API_BASE_URL + '/public/catalog/models/' + modelSku)
          .then(res => {
            commit('CONFIGURATOR_SELECT_MODEL', res.data)
            resolve()
          })
          .catch(() => {
            commit('CONFIGURATOR_ERROR')
            resolve()
          })
      })
    },
    CONFIGURATOR_EXPORT({ state }) {
      return new Promise(resolve => {
        resolve({
          ...state.actualModel,
          belt: {
            ...state.actualModel.belt,
            transform: {}
          },
          currentLogoMode: state.currentLogoMode,
          actualStyle: state.actualStyle,
          styles: { ...state.actualModel.styles[state.actualStyle] },
          size: {
            actualSize: { ...state.actualModel.size.actualSize }
          },
          stdSizeCustomization: state.stdSizeCustomization
        })
      })
    },
    CONFIGURATOR_IMPORT({ commit }, payload) {
      commit('CONFIGURATOR_IMPORT', payload)
    },
    CONFIGURATOR_INITAL_VALUES({ commit }) {
      commit('CONFIGURATOR_INITAL_VALUES')
    },
    CONFIGURATOR_SET_STDSIZE_CUSTOMIZATION({ commit, getters }, value) {
      commit('CONFIGURATOR_SET_STDSIZE_CUSTOMIZATION', value)
      if (value === false) {
        commit('CONFIGURATOR_CHANGE_SIZETYPE', { type: 'standard', measure: getters.actualSize.measure })
      }
    },
    CONFIGURATOR_SET_LOGO_MODE({ commit }, value) {
      commit('CONFIGURATOR_SET_LOGO_MODE', value)
    },
    CONFIGURATOR_GOTO_PREV_STATE({ commit, getters }) {
      const currentStepIndex = getters.configuratorCurrentStepIndex
      if (getters.configuratorSteps[currentStepIndex - 1] && getters.configuratorSteps[currentStepIndex - 1].id) {
        commit('CONFIGURATOR_CHANGE_STATE', getters.configuratorSteps[currentStepIndex - 1].id)
      }
    },
    CONFIGURATOR_GOTO_NEXT_STATE({ commit, getters }) {
      const currentStepIndex = getters.configuratorCurrentStepIndex
      if (getters.configuratorSteps[currentStepIndex + 1] && getters.configuratorSteps[currentStepIndex + 1].id) {
        commit('CONFIGURATOR_CHANGE_STATE', getters.configuratorSteps[currentStepIndex + 1].id)
      }
    },
    CONFIGURATOR_SET_SIZEYOU_MEASURES({ commit }, payload) {
      commit('CONFIGURATOR_SET_SIZEYOU_MEASURES', payload)
    }
  }
}
