import axios from 'axios'

export default {
  state: {
    isFetching: false,
    error: null,
    params: null,
    userData: null,
    measures: null
  },
  getters: {
    isSizeYouFetching: state => state.isFetching,
    sizeYouError: state => state.error,
    sizeYouParams: state => state.params,
    sizeYouHasParams: state => Boolean(state.params),
    sizeYouStateNumber: () => {
      const min = 10000000000000000000
      const max = 99999999999999999999
      return Math.floor(Math.random() * (max - min + 1)) + min
    }
  },
  mutations: {
    SIZEYOU_IS_FETCHING(state, value = true) {
      state.isFetching = value
      state.error = null
    },
    SIZEYOU_ERROR(state, payload) {
      state.isFetching = false
      state.error = payload
    },
    SIZEYOU_GET_PARAMS_SUCCESS(state, data) {
      state.isFetching = false
      state.error = null
      state.params = { ...data }
    }
  },
  actions: {
    SIZEYOU_GET_PARAMS({ commit }, type) {
      return new Promise(resolve => {
        commit('SIZEYOU_IS_FETCHING')
        axios
          .get(process.env.VUE_APP_API_BASE_URL + '/public/sizeyou/' + type)
          .then(res => {
            commit('SIZEYOU_GET_PARAMS_SUCCESS', res.data)
            resolve()
          })
          .catch(() => {
            commit('SIZEYOU_ERROR', true)
            resolve()
          })
      })
    },
    SIZEYOU_POST_USER({ commit }, payload) {
      return new Promise(resolve => {
        commit('SIZEYOU_IS_FETCHING')
        axios
          .post(payload.url, payload.data)
          .then(res => {
            commit('SIZEYOU_IS_FETCHING', false)
            if (res.data && res.data.user_data && res.data.user_measures && res.data.user_measures.length > 0) {
              resolve(res.data)
            } else {
              commit('SIZEYOU_ERROR', true)
              resolve()
            }
          })
          .catch(() => {
            commit('SIZEYOU_ERROR', true)
            resolve()
          })
      })
    }
  }
}
