import axios from 'axios'

export default {
  state: {
    defaultParams: {
      sortBy: 'code',
      sortDir: 'DESC',
      page: 1,
      limit: 20
    },
    isFormOpened: false,
    queryParams: {},
    isFetching: false,
    error: null,
    total: 0,
    items: [],
    isDeleting: [],
    selectedItem: {},
    isEditing: false
  },
  getters: {
    isDealersFetching: state => state.isFetching,
    isDealerDeleting: state => id => state.isDeleting.includes(id),
    isDealersFormOpened: state => state.isFormOpened,
    dealersDefaultParams: state => state.defaultParams,
    dealers: state => state.items,
    dealersModel: state => state.selectedItem,
    dealersIsEditing: state => state.isEditing,
    dealersTotal: state => state.total,
    dealersError: state => (state.error && state.error.status !== 400 ? state.error : null),
    submitError: state => (state.error && state.error.status === 400 ? state.error : null)
  },
  mutations: {
    DEALERS_IS_FETCHING(state) {
      state.isFetching = true
      state.error = null
    },
    DEALERS_IS_NOT_FETCHING(state) {
      state.isFetching = false
      state.error = null
    },
    DEALERS_SET_FORM_OPENED(state, value) {
      state.isFormOpened = Boolean(value)
      if (value === false) {
        state.selectedItem = {}
        state.isEditing = false
      }
    },
    DEALERS_GET_LIST_SUCCESS(state, payload) {
      state.isFetching = false
      state.error = null
      state.selectedItem = {}
      state.queryParams = { ...payload.params, ...state.queryParams }
      state.items = payload.data.items
      state.total = payload.data.total
    },
    DEALERS_GET_ITEM_SUCCESS(state, payload) {
      state.isFetching = false
      state.error = null
      state.isEditing = true
      state.selectedItem = payload
    },
    DEALERS_ERROR(state, payload) {
      state.isFetching = false
      state.error = payload
    },
    DEALERS_SET_IS_DELETING(state, payload) {
      if (payload.value === true) {
        state.isDeleting = [...state.isDeleting, payload.id]
      } else {
        state.isDeleting = state.isDeleting.filter(x => x !== payload.id)
      }
    },
    AUTH_LOGOUT(state) {
      state.isFormOpened = false
      state.queryParams = {}
      state.isFetching = false
      state.error = null
      state.total = 0
      state.items = []
      state.isDeleting = []
      state.selectedItem = {}
      state.isEditing = false
    }
  },
  actions: {
    DEALERS_GET_LIST({ commit }, params) {
      commit('DEALERS_IS_FETCHING')
      const queryString = Object.keys(params)
        .reduce((res, e) => [...res, e + '=' + params[e].toString()], [])
        .join('&')

      axios
        .get(process.env.VUE_APP_API_BASE_URL + '/dealers?' + queryString)
        .then(res => {
          commit('DEALERS_GET_LIST_SUCCESS', { data: res.data, params })
        })
        .catch(error => {
          commit('DEALERS_ERROR', { status: error.response.status, ...error.response.data })
        })
    },
    DEALERS_GET_ITEM({ commit }, id) {
      commit('DEALERS_IS_FETCHING')
      axios
        .get(process.env.VUE_APP_API_BASE_URL + '/dealers/' + id)
        .then(res => {
          commit('DEALERS_GET_ITEM_SUCCESS', res.data)
        })
        .catch(error => {
          commit('DEALERS_ERROR', { status: error.response.status, ...error.response.data })
        })
    },
    DEALERS_SET_FORM_OPENED({ commit }, value) {
      commit('DEALERS_SET_FORM_OPENED', value)
    },
    DEALERS_CREATE({ dispatch, commit, state }, payload) {
      commit('DEALERS_IS_FETCHING')
      dispatch('DEALER_READ_LOGO', payload.logo)
        .then(logo => {
          payload.logo = logo
          axios
            .post(process.env.VUE_APP_API_BASE_URL + '/dealers', payload)
            .then(() => {
              dispatch('DEALERS_GET_LIST', state.queryParams)
            })
            .catch(error => {
              commit('DEALERS_ERROR', { status: error.response.status, ...error.response.data })
            })
        })
        .catch(error => {
          commit('DEALERS_ERROR', { status: error.response.status, ...error.response.data })
        })
    },
    DEALERS_EDIT({ dispatch, commit, state }, payload) {
      const whiteListProps = [
        'code',
        'name',
        'email',
        'company',
        'discountPercentage',
        'logo',
        'b2cPrice',
        'hideDescrPrice'
      ]
      commit('DEALERS_IS_FETCHING')

      dispatch('DEALER_READ_LOGO', payload.logo)
        .then(logo => {
          payload.logo = logo
          axios
            .put(
              process.env.VUE_APP_API_BASE_URL + '/dealers/' + payload.id,
              Object.keys(payload)
                .filter(e => whiteListProps.includes(e))
                .reduce((res, e) => ({ ...res, [e]: payload[e] }), {})
            )
            .then(() => {
              dispatch('DEALERS_SET_FORM_OPENED', false)
              dispatch('DEALERS_GET_LIST', state.queryParams)
            })
            .catch(error => {
              commit('DEALERS_ERROR', { status: error.response.status, ...error.response.data })
            })
        })
        .catch(error => {
          commit('DEALERS_ERROR', { status: error.response.status, ...error.response.data })
        })
    },
    DEALERS_SET_IS_DELETING({ commit }, payload) {
      commit('DEALERS_SET_IS_DELETING', payload)
    },
    DEALERS_DELETE({ dispatch, commit, state }, id) {
      commit('DEALERS_IS_FETCHING')
      axios
        .delete(process.env.VUE_APP_API_BASE_URL + '/dealers/' + id)
        .then(() => {
          dispatch('DEALERS_GET_LIST', state.queryParams)
        })
        .catch(error => {
          commit('DEALERS_ERROR', { status: error.response.status, ...error.response.data })
        })
    },
    DEALER_READ_LOGO(params, logoFile) {
      return new Promise((resolve, reject) => {
        if (logoFile && typeof logoFile === 'object') {
          const reader = new FileReader()
          reader.readAsDataURL(logoFile)
          reader.onload = () =>
            resolve({
              type: logoFile.type,
              data: reader.result
            })
          reader.onerror = error => reject(error)
        } else {
          resolve(null)
        }
      })
    },
    DEALERS_GET_EXPORT_LIST({ commit }) {
      return new Promise(resolve => {
        commit('DEALERS_IS_FETCHING')
        axios
          .get(process.env.VUE_APP_API_BASE_URL + '/dealers/list', { responseType: 'arraybuffer' })
          .then(res => {
            commit('DEALERS_IS_NOT_FETCHING')
            resolve(res.data)
          })
          .catch(error => {
            commit('DEALERS_ERROR', { status: error.response.status, ...error.response.data })
          })
      })
    }
  }
}
