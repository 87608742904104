<template>
  <header class="Header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-3 col-xl-4">
          <router-link :to="{ name: 'dashboard' }">
            <img class="Header__logo img-fluid small" :src="logo" />
            <img class="Header__logo img-fluid" :src="logoCEasy" />
          </router-link>
        </div>
        <div class="col-lg-5 col-xl-4">
          <ul class="Header__menu text-center" v-if="isAdmin">
            <li class="Header__menu-item float-left">
              <router-link class="Header__menu-item-link" :to="{ name: 'dashboard' }">{{
                $t('HEADER_ORDERS_MENU')
              }}</router-link>
            </li>
            <li class="Header__menu-item float-left">
              <router-link class="Header__menu-item-link" :to="{ name: 'customers' }">{{
                $t('HEADER_CUSTOMERS_MENU')
              }}</router-link>
            </li>
            <li class="Header__menu-item float-left">
              <router-link class="Header__menu-item-link" :to="{ name: 'parameters' }">{{
                $t('HEADER_PARAMETERS_MENU')
              }}</router-link>
            </li>
          </ul>
          <ul class="Header__menu text-center" v-if="!isAdmin">
            <li class="Header__menu-item float-left dealer-link">
              <router-link class="Header__menu-item-link" :to="{ name: 'dashboard' }">
                {{ $t('HEADER_B2B_ORDER') }}
              </router-link>
            </li>
            <li class="Header__menu-item float-left dealer-link">
              <router-link class="Header__menu-item-link" :to="{ name: 'B2C_dashboard' }">
                {{ $t('HEADER_B2C_ORDER') }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-lg-4 col-xl-4">
          <div class="Header__operations row text-right">
            <div class="col-4 col-xl-5">
              <LanguageSwitch />
            </div>
            <div class="col-8 col-xl-7" v-if="loggedUser">
              <div class="Header__user">
                <div class="float-left">
                  <div class="Header__user-data" :title="loggedUser.name">
                    <strong>{{ loggedUser.name }}</strong>
                  </div>
                  <div class="Header__user-data" :title="loggedUser.email">{{ loggedUser.email }}</div>
                </div>
                <div class="float-left">
                  <a tabindex="0" v-if="!loggedUser.logo" id="header-menu" class="Header__user-icon">
                    <Account />
                  </a>
                  <a
                    tabindex="0"
                    v-if="loggedUser.logo"
                    id="header-menu"
                    :style="`background-image: url(${loggedUser.logo})`"
                    class="Header__user-logo"
                  >
                  </a>
                </div>
                <b-popover target="header-menu" placement="bottom" triggers="focus">
                  <button class="Header__logout" @click="logout()"><LogoutVariant /> {{ $t('HEADER_LOGOUT') }}</button>
                </b-popover>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import Account from 'vue-material-design-icons/Account.vue'
import LogoutVariant from 'vue-material-design-icons/LogoutVariant.vue'
import LanguageSwitch from '@/components/LanguageSwitch.vue'
import logo from '@/assets/logo_sparco.svg'
import logoCEasy from '@/assets/logo_customeasy_oriz.png'

export default {
  name: 'Header',
  components: {
    Account,
    LogoutVariant,
    LanguageSwitch
  },
  data() {
    return {
      logo,
      logoCEasy
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'isAdmin', 'loggedUser']),
    currentRoute() {
      return this.$route.name
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('AUTH_LOGOUT').then(() => {
        this.$router.push({ name: 'login' })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.Header {
  box-shadow: 0 1px 6px 0 rgba($c-black, 0.25);
  background: $c-white;
  padding-top: 15px;

  &--no-menu {
    padding-bottom: 10px;
  }

  &__logo {
    height: 45px;
    &.small {
      height: 20px;
      margin-right: 10px;
    }
  }

  &__menu {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;

    li {
      max-width: 33%;
      &.dealer-link {
        max-width: 50%;
      }
    }
  }

  &__menu-item {
    width: 22%;
    &:first-child {
      margin-left: 15%;
    }
    &.dealer-link {
      width: 40%;
    }
  }

  &__menu-item-link {
    display: block;
    color: $c-text;
    background: none;
    border: none;
    border-bottom: 5px solid $c-white;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 10px;
    padding-bottom: 25px;
    text-decoration: none;

    &.router-link-active {
      border-color: $c-blue;
      color: $c-blue;
      font-weight: bold;
    }
  }

  &__user {
    overflow: hidden;
    display: inline-block;
  }

  &__user-data {
    overflow: hidden;
    max-width: 133px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__user-icon {
    display: block;
    cursor: pointer;
    background: $c-blue;
    color: $c-white;
    border-radius: 50%;
    font-size: 20px;
    padding: 10px 15px;
    margin-left: 10px;
  }

  &__user-logo {
    display: block;
    width: 48px;
    height: 48px;
    border: 1px solid $c-blue;
    cursor: pointer;
    border-radius: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 10px 15px;
    margin-left: 10px;
  }

  &__logout {
    width: 150px;
    background: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
    .material-design-icon {
      color: $c-blue;
    }
    &:hover {
      color: $c-blue;
    }
  }
}
</style>
