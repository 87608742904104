import axios from 'axios'

export default {
  state: {
    isFetching: false,
    error: null,
    items: [],
    isDeleting: []
  },
  getters: {
    isLogosFetching: state => state.isFetching,
    isLogosDeleting: state => logo => state.isDeleting.includes(logo),
    logosError: state => state.error,
    logosItems: state => state.items
  },
  mutations: {
    LOGOS_IS_FETCHING(state) {
      state.isFetching = true
      state.error = null
    },
    LOGOS_GET_SUCCESS(state, payload) {
      state.isFetching = false
      state.items = payload
    },
    LOGOS_ERROR(state, payload) {
      state.isFetching = false
      state.error = payload
    },
    LOGOS_SET_IS_DELETING(state, payload) {
      if (payload.value === true) {
        state.isDeleting = [...state.isDeleting, payload.logo]
      } else {
        state.isDeleting = state.isDeleting.filter(x => x !== payload.logo)
      }
    },
    AUTH_LOGOUT(state) {
      state.isFetching = false
      state.error = null
      state.items = []
      state.isDeleting = []
    }
  },
  actions: {
    LOGOS_GET_LIST({ commit }) {
      return new Promise(resolve => {
        commit('LOGOS_IS_FETCHING')
        axios
          .get(process.env.VUE_APP_API_BASE_URL + '/public/logos')
          .then(res => {
            commit('LOGOS_GET_SUCCESS', res.data)
            resolve()
          })
          .catch(error => {
            commit('LOGOS_ERROR', error.response)
            resolve()
          })
      })
    },
    LOGOS_UPLOAD({ dispatch, commit }, payload) {
      return new Promise(resolve => {
        commit('LOGOS_IS_FETCHING')
        axios
          .post(process.env.VUE_APP_API_BASE_URL + '/logos', payload)
          .then(() => {
            dispatch('LOGOS_GET_LIST').then(() => resolve())
          })
          .catch(error => {
            commit('LOGOS_ERROR', error.response)
            resolve()
          })
      })
    },
    LOGOS_SET_IS_DELETING({ commit }, payload) {
      commit('LOGOS_SET_IS_DELETING', payload)
    },
    LOGOS_DELETE({ dispatch, commit }, id) {
      return new Promise(resolve => {
        commit('LOGOS_IS_FETCHING')
        axios
          .delete(process.env.VUE_APP_API_BASE_URL + '/logos/' + id)
          .then(() => {
            dispatch('LOGOS_GET_LIST').then(() => resolve())
          })
          .catch(error => {
            commit('ORDERS_ERROR', error.response.data.code)
            resolve()
          })
      })
    }
  }
}
